@import '../../styles/variables';

$screen-laptop1024: 1024px;
$screen-laptop1280: 1280px;
$screen-laptop1440: 1440px;
$screen-4k: 2560px;



.container {
  display: flex;
  background-color:#F5F6F8;
  min-height: 100vh;
  

}

  .content {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    padding: 0 1.5rem;
    background-color: #F5F6F8;
    
  }

  .mainContainer {
 
    margin-top: 10rem;
    background-color: #fff;
    border-radius: 25px;
    width: 100%;
    margin-bottom: 1.25rem;
    overflow-x: auto;
    
    
  }

   

  .header {
    padding: 2rem 2rem 0rem 2rem;
    

    .topHeader {
      h2 {
        color: $gray-500;
        margin-bottom: 1.25rem;
        font-weight: 400;
        font-size: 1.25rem;
        
      }

      button {
        text-transform: none !important;
        padding: 0;
        margin-right: 1.25rem;
        
        
      }
    }
  }

  .filterContainertabPanel {
    border-bottom: 1px solid $gray-50;
    display: flex;
    align-items: center;
    padding: 1rem 1rem 1rem 2rem !important;

    h3 {
      color: $gray-500;
      font-weight: 400;
      margin-right: 0.8rem;
      
      
    }

    input {
      height: 30px;
      opacity: 0;
      display: none;
      visibility: hidden;
      
    }

    label {
      height: 35px;
      padding: 5px 25px;
      border: none;
      border-radius: 50px;
      background-color: $white;
      color: $gray-100;
      margin-right: 0.8rem;
      
    }

    input[type="radio"]:checked+label {
      border: none;
      border-radius: 50px;
      background-color: $blue-50;
      color: $gray-500;
      margin-right: 0.8rem;
      
    }
  }
  

  .searchAndAddContainer {
    border-bottom: 1px solid $gray-50;
    padding: 1rem 2rem !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    

    .formControle {
      padding: 8px 0px;
    }

    .fixMuiInputBaseRoot {
      height: 40px;
      padding-left: 1rem;
      display: flex;
      align-items: center;
      width: 300px !important;
    }

    input[type=text] {
      height: 38px;
      width: 350px;
      padding: 0px 10px;
      box-sizing: border-box;
      border: 1px solid $gray-50;
      border-radius: 5px;
      transition: 1s;
      outline: none;
    }

    input[type=text]:focus {
      border: none;
    }

    button {
      background: $global-gradient-blue-green;
      width: 14rem;
      height: 40px;
      border-radius: 5px;
      border: none;
      color: $white;
      text-transform: uppercase;
      font-size: 0.8rem
      
    }
  }

  .contentTitle {
    background-color: $blue-40;
    padding: 1rem 2rem !important;
    
    

    h3 {
      color: $gray-500;
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      line-height: 128.91%;
      
    }
  }

  .LoadingScreen {
    height: 400px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
  }


  




