    .chartContainer {
        display: flex;
        flex-direction: column;
        padding: 2.8vh 2vw;
        border-radius: 24px;
        margin-top: 2.5vh;
        height: 39.6vh;
        background-color: #FFFFFF;
        overflow: visible;

        .dateFormattedContainer {
            width: 100%;
            display: flex;
            justify-content: space-between;

            .equipmentSelectAndDatePickerContainer {
                display: flex;
                justify-content: center;
            }
        }

        .areaDate {
            background-color: #EAF2F6;
            padding-inline: .625vw;
            border-radius: 5px;
            height: fit-content;
            width: fit-content;
            color: #14394C;
            font-size: 1.66vh;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    }


    
    @media (max-width: 800px) and (min-height: 301px) and (max-height: 400px) {
        .chartContainer {
            
            height: 60vh;
           
    }
}


@media (max-width: 600px) and (max-height: 400px) {
    .chartContainer {
        
        height: 60vh;
       
}
}

@media (max-width: 500px) and (max-height: 300px) {
    .chartContainer {
        
        height: 80vh;
       
}
}