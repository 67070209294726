@import "../../styles/variables";

html, body {
  padding: 0;
  margin: 0;
  height: auto; 
}

body {
  height: var(--doc-height);
}

.container { 
  width: 100%; 
  display: flex; 
  justify-content: center;
  height: auto; 
  padding: 2vh 5vh 2vh 5vh;
  background-color: #F5F6F8;
}

.content {
  display: flex;
  flex-direction: column; 
  align-items: center; 
  width: 100%; 
  height: 100%; 
}

.box {
  width: 100%; 
  padding: 0; 
  display: flex;
  flex-direction: column;
  align-items: center;
}

.firstContainerChart {
  display: flex;
  justify-content: space-between; 
  width: 100%; 
  margin-top: 10vh;
  // background-color: #344e80;
}

.loadingScreen {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80vw;
  height: 100vh;
}

.noUnitConsumer{
  z-index: 10;
  position: absolute;
  top: 50%;


}

@media (min-width: 0px) and (max-width: 360px) {

  .firstContainerChart {
    flex-direction: column;
    margin-top: 6rem !important;

  }
  
  .container{
    padding: 8rem 1rem 1rem 1rem ;


  }
}






@media (min-width: 361px) and (max-width: 375px) {
  .firstContainerChart {
    flex-direction: column;
    margin-top: 6rem !important;
  }
  .container{
    padding: 8rem 1rem 1rem 1rem ;
  }
}

@media (min-width: 376px) and (max-width: 390px) {

  .firstContainerChart {
    flex-direction: column;
    margin-top: 6rem;
    // background-color: rgb(215, 55, 37);
  }
  .container{
    padding: 8rem 1rem 1rem 1rem ;

  }
}

@media (min-width: 391px) and (max-width: 414px) {

  .firstContainerChart {
    flex-direction: column;
    margin-top: 6rem;
    // background-color: rgb(215, 55, 37);
  }
  .container{
    padding: 8rem 1rem 1rem 1rem ;
    // background-color: bisque;
  }
}
@media (min-width: 415px) and (max-width: 480px) {

  .firstContainerChart {
    flex-direction: column;
    margin-top: 6rem;
    // background-color: rgb(215, 55, 37);
  }
  .container{
    padding: 8rem 1rem 1rem 1rem ;
    // background-color: bisque;
  }
}

@media (min-width: 481px) and (max-width: 600px) {

  .firstContainerChart {
    flex-direction: column;
    margin-top: 2rem !important;
    // background-color: rgb(215, 55, 37);
  }
  .container{
    padding: 8rem 1rem 1rem 1rem ;

    
  }
}


@media (min-width: 601px) and (max-width: 768px) {

  .firstContainerChart {
    flex-direction: column;
    margin-top: 2rem;
    // background-color: rgb(215, 55, 37);
  }
  .container{
    padding: 8rem 1rem 1rem 1rem ;

   
  }
}
@media (min-width: 769px) and (max-width:  800px) {

  .firstContainerChart {
    flex-direction: column;
    margin-top: 2rem;
    // background-color: rgb(215, 55, 37);
  }
  .container{
    padding: 8rem 1rem 1rem 1rem ;

   
  }
}


@media (min-width: 801px) and (max-width: 1024px) {

  .firstContainerChart {
    margin-top: -2rem;
  }
  .container{
    padding: 8rem 1rem 1rem 1rem ;
   
  }
}



@media (min-width: 1025px) and (max-width: 1280px) {
.firstContainerChart{
  margin-top: 10vh;
}

}


@media (min-width: 1281px) and (max-width: 1440px) {
  .firstContainerChart{
    margin-top: 10vh;
  }
    
  }



  @media (max-width: 1024px) and (max-height: 700px) {
    .firstContainerChart {
      margin-top: 0rem;

    }
    
    .container{
      padding: 8rem 1rem 1rem 1rem ;
 
    }
  }

  @media (max-width: 1280px) and (max-height: 700px) {
    .firstContainerChart {
      margin-top: -2rem;

    }
    
    .container{
      padding: 8rem 1rem 1rem 1rem ;

    }
  }
  
  
  
  @media (max-width: 500px) and (max-height: 300px) {

   

    .firstContainerChart {
      margin-top: 4rem !important;

    }
  }

  
