@import '../../styles/variables';

$screen-laptop1024: 1024px;
$screen-laptop1280: 1280px;
$screen-laptop1440: 1440px;
$screen-4k: 2560px;


.container {
  display: flex;
  background-color: #F5F6F8;
  //filter: brightness(1.8);
  background-size: 350%;
  min-height: 100vh;
  
  background-position: 0px -70px ;

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 1.5rem;

    
  }

  .mainContainer {
    margin-top: 12rem;
    border-radius: 25px;
    background-color: #fff;
    margin-bottom: 1.25rem;
    overflow-x: auto;
    
  }

  .header {
    border-bottom: 1px solid $gray-50;
     padding: 2rem 2rem 0rem 2rem;

    .topHeader {
      height: 100px;
      margin-bottom: 1rem;

      
        h2 {
        color: $gray-500;
        margin-bottom: 1rem;
        font-weight: 400;
        font-size: 1.25rem;       
      
      }


      input[type=text] {
        height: 38px;
        width: 350px;
        padding: 0px 10px;
        box-sizing: border-box;
        border: none;
        border-radius: 5px;
        transition: 1s;
        outline: none;
        margin-bottom: 1rem;
      }

      input[type=text]:focus {
        border: none;
      }
    }

    .filterContainer {
      display: flex;
      align-items: center;
      

      h3 {
        color: $gray-500;
        font-weight: 200;
        margin-right: 0.5rem;
        margin-left: -0.7rem;
        //background-color: aquamarine;
        font-size: 13px;
        
      }

      button {
        padding: 2px;
        height: 35px;
        width: 150px;
        border: none;
        border-radius: 5px;
        background-color: $white;
        color: $gray-100;
        margin-right: 0.8rem;
        transition: color .5s ease-in-out, box-shadow .3s ease-in-out;
        
        
        &:hover {
          box-shadow: inset 200px 0 0 0 $blue-500;;
          color: #fff;
          border: 1px solid #fff;
        }
        &:active {
          box-shadow: inset 200px 0 0 0 $blue-500;
          color: #fff;
          border: 1px solid #fff;
        }
      }
    }
  }


  .section {
    .itemContainer {
      display: flex;
      flex-direction: column;
      padding-bottom: 1rem !important;
    }

    .titletable {
      display: grid;
      grid-template-columns: 35px 1fr 1fr 1fr 1fr 1fr;
      gap: 5px;
      background-color: $blue-25;
      padding: 0rem 2rem !important;

      .contentHeader {
        padding: 0.5rem 0rem;

        h5 {
          color: $gray-500;
          font-weight: 400;
          font-size: 0.8rem;
          text-align: left;
          
        }
      }
    }

    .mainContent {
      display: grid;
      grid-template-columns: 35px 1fr 1fr 1fr 1fr 1fr;
      gap: 5px;
      padding: 1rem 2rem !important;
      align-items: flex-start;
      
      
      

      .contentText {
        max-width: 200px;
        h4 {
          color: $gray-500;
          font-weight: 400;
          font-size: 1rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          //background-color: brown;
        }
      }
    }

    .contentBox3 {
      .contentItem {
        display: grid;
        align-items: center;
        justify-content: flex-end;
        background-color: $white;
        padding: 0 1.25rem;
        grid-template-columns: 40px 40px;
        gap: 0px;
        background-color: rgba(220, 224, 233, 0);

        button {
          border: none;
          background: transparent;
          color: $red-200;
        }

        button:first-child {
          color: $blue-500;
        }
      }
    }
  }

  .containerButtons {
    width: 100%;
    background-color: $white;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 0rem 2rem 1rem 2rem !important;
    border-radius: 5px, 5px;
    margin-bottom: 1.25rem;

    button {
      width: 180px;
      height: 40px;
      padding: 0.25rem;
      border: none;
      background: $global-gradient-blue-green;
      border-radius: 5px;
      color: $white;
      font-weight: 400;
      font-size: 14px;
      margin-bottom: 1rem;
      text-transform: uppercase;
    }
  }

  .statusContainer {
    display: flex;
    align-items: center;

    p {
      color: $gray-100 !important;
    }

    .status {
      width: 10px;
      height: 10px;
      border-radius: 5px;
      border: 3px solid green;
      margin-left: 10px !important;
    }
  }


  .dividerHorizontal {
    width: 100%;
    height: 1px;
    margin-top: 10px;
    background-color: $gray-50;
  }


  .section {
    width: 100%;
    border-radius: 5px;
    border-collapse: collapse;
    padding: 15px;
    border-radius: 5px;

    thead {
      background-color: $blue-50;
      display: grid;
      grid-template-columns: 100px 1fr 1fr 1fr 1fr 1fr;

      tr {
        color: $gray-500;
        font-weight: 400;
        font-size: 1rem;
        text-align: left;
        padding: 1.25rem;
      }

      tr:last-child() {
        display: flex;
        flex-direction: row;
      }
    }

    tbody {
      display: grid;
      grid-template-columns: 100px 1fr 1fr 1fr 1fr 1fr;

      td {
        color: $gray-500;
        font-weight: 400;
        padding: 1.25rem;
        border-bottom: 1px solid $gray-50;
      }

      td:first-child {
        padding: 1.25rem 1.25rem 1.25rem 2.5rem;
      }

      td:last-child {
        padding: 1.25rem 2.5rem 1.25rem 1.25rem;
      }

      .iconCircle {
        width: 40px;
        height: 40px;
        background-color: $blue-50;
        border-radius: 5px;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .icon {
        color: $blue-500;
      }

      td div {
        display: flex;
        flex-direction: row;

        button {
          border: none;
          background: transparent;
          color: $red-200;
        }

        button:first-child {
          margin-right: 10px;
          color: $blue-500;
        }

      }
    }
  }

  .LoadingScreen {
    height: 400px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  }

  @media (max-width: 900px) {
    .mainContent {
      
      overflow-x: auto;
    }
  
    header .Input  {
      width: 70% !important;
      
     
  
  }
  }
