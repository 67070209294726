$white: #fff;
$black: #000;

$gray-50: #c8cbcc;
$gray-100: #666;
$gray-500: #333;

$blue-10: #FCFDFF;
$blue-25: #F4F8FD;
$blue-40: #E9F0FA;
$blue-50: #edf2f8;
$blue-60: #DAE4F1;
$blue-70: #aeccf3;
$blue-100: #76B3FF;
$blue-500: #0f85ca;

$red-100: #ffa6a6;
$red-200: #fa4949;
$pink-100: #FFD9FA;
$yellow-100: #FFE1A6;
$green-100: #77E3C7;

$gradient-White: #f4f9fd;
$gradient-blue: #0F85CA;
$gradient-green: #27B291;

$background: #FBFDFF;
$global-gradient-white: linear-gradient(288.9deg, #FFFFFF 0%, #F4F9FD 74.5%);
$global-gradient-blue-green: linear-gradient(94.87deg, #0F85CA 7.1%, #27B291 104.95%);



//NEW LAYOUT ICC

//dashboad global

$select-Items:#44521B;

$bgchart: #F5F6F8;
$titles: #14394C;
$circle-Blue: #EAF2F6;
$button-Green: #BDDB6C;
$ticks-Gray: #BDBDBD;
$user-green: #71892D;
$help-Gray: #F5F8EC;
$gray-70: #E3E3E3;


//charts

$bg-White:#FFFFFF;
$blue-1:#3BB2D0;
$blue-2:#3887BE;
$purple-800:#8B4F80;
$purple-600:#B1489E;
$purple-300:#C28EED;
$purple-100: #D3C0CC;
$blue-300:#8A8ACB;
$orange: #FBB03B;
$outros-Blue:#50667F;
$red-300: #F9886C;
$red-800:#E55E5E;
$pink:#ED6498;
$green-300:#C8E96D;
$green-800:#2ABC80;


$ponta-Red:#E23D2899;
$intermediario: #F68D2B99;

$bar-Blue:#676ACA;
