@import '../../../../../styles/variables';


.NotConsumer {
  display: flex; /* Define o container como flexbox */
  justify-content: center; /* Centraliza horizontalmente */
  align-items: center; /* Centraliza verticalmente */
  height: 100%; /* Ajuste conforme necessário para a altura total */
  text-align: center; /* Para manter o texto centralizado se quebrar linha */
  
}

.NotConsumer h2 {
  font-size: 0.8rem;
  color: $titles;
}

.blurLoading{

  height: 100%; /* Ajuste conforme necessário para a altura total */
  display: flex; /* Define o container como flexbox */
  justify-content: center; /* Centraliza horizontalmente */
  align-items: center; /* Centraliza verticalmente */

}
  .blurLoading h2{
    font-size: 0.8rem;
  color: $titles;
  
  
  }
  

  .boxChart {
    display: flex;
    flex-direction: column; /* Para exibir o gráfico e a legenda em coluna */
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 30vh; /* Ocupa toda a altura disponível */
    // background-color: rgb(233, 181, 181);
    overflow: hidden;
    position: relative;
  }
  
  
  @media (max-width: 800px) and (max-height: 400px) {

    .boxChart{

      margin-top: 4rem;
    }

  }


  
  @media (max-width: 359px) {
    .NotConsumer h2{
      font-size: 0.4rem;
      
      }

  }

  @media (min-width: 360px) and (max-width: 420px) {

    .NotConsumer h2{
      font-size: 0.4rem;
  
      }

  
  }