@import "../../../../../styles/variables";

.middleCard {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 25px;
  padding: 1rem;
  margin-right: 2vh;
  height: 42vh;
  width: 50% !important;
  position: relative;
}

.CardHeader {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0.5rem 1.5rem;

  .containerTitle {
    display: flex;
    flex-direction: column;
  }

  h3 {
    color: $titles;
    font-style: normal;
    font-weight: 700;
    font-size: clamp(1rem, 1.5vw, 1.5rem);
    margin: 0;
  }

  h5 {
    color: #14394c;
    opacity: 0.7;
    font-family: Inter;
    font-size: clamp(0.7rem, 1vw, 1rem);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: -0.3rem;
  }
}


//INFOCARD

.info__chart__container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-height: 100%;

  @media (max-width: 480px) {
    flex-direction: column;
    padding-top: 2rem;
    align-items: flex-start;
    justify-content: flex-start;

    &.zoom-400,
    &.zoom-500 {
      flex-direction: row;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: .5em;
      width: 100%;
      height: 100%;
      max-height: 100%;
    }

  }


}

.Informative {
  width: 31%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 800px) {
    &.zoom-175 {
      width: 28%;
      margin-bottom: 1rem;
    }

    &.zoom-200 {
      width: 28%;
      margin-bottom: 1rem;
    }

    &.zoom-400,
    &.zoom-500 {
      width: 31%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 2.5rem;
    }
  }

  @media (max-width: 480px) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .totals {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @media (max-width: 480px) {
      gap: 1.5rem;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
    }

    &.zoom-400,
    &.zoom-500 {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }

  .column {
    display: flex;
    flex-direction: column;
  }

  .value {
    font-size: 2.2256rem;
    line-height: 2.6875rem;
    color: #14394C;
    font-weight: 700;


    @media (max-width: 480px) {
      font-size: 1.5rem;
      line-height: 1.8rem;
    }

    @media (max-width: 1400px) {
      font-size: 1.4rem;
      line-height: 1.7rem;
    }

    &.zoom-175,
    &.zoom-200 {
      font-size: 20px;
      line-height: 1.2rem;
    }

    &.zoom-500 {
      font-size: 16px;
      line-height: 1rem;
    }

  }

  .unit {

    &.zoom-175,
    &.zoom-200 {
      font-size: 14px !important;
    }

    &.zoom-500 {
      font-size: 11px !important;
    }



  }

  .value__text {
    color: #14394C;
    opacity: 0.7;
    font-weight: 400;
    font-size: 1.1125rem;
    margin-top: -.4rem;

    @media (max-width: 650px) {
      font-size: 1rem;
      margin-top: -.2rem;
    }

    @media (max-width: 1400px) {
      font-size: .85rem;
      margin-top: -.1rem;
    }

    &.zoom-175,
    &.zoom-200 {
      font-size: 11px;
    }

    &.zoom-500 {
      font-size: 9px;
    }
  }
}

.PieArea {
  width: 100%;
  align-content: center;
  justify-content: center;
  flex: 1;
  display: flex;
  color: lightgray;

  &.zoom-400,
  &.zoom-500 {
    height: 100%;
  }
}

.containerIcon {
  display: flex;
}

.sectionIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 100px;
  gap: 1rem;
  cursor: pointer;
}

.circleIcon {
  background-color: #eaf2f6;
  padding: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  font-size: 10px;
  color: $blue-500;
  width: 48px !important;
  height: 48px !important;



  @media (max-width: 650px) {
    width: 30px !important;
    height: 30px !important;
    padding: 0.4rem;
  }
}


@media (max-width: 1280px) {

  .circleIcon {

    padding: clamp(0.4rem, 1vw, 0.6rem);

    width: clamp(30px, 6vw, 38px) !important;
    height: clamp(30px, 6vw, 38px) !important;
  }

}




// ----------- STYLES FOR TABLETS -----------

.CardHeader__tablet {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0.5rem 1.5rem;

  .containerTitle {
    display: flex;
    flex-direction: column;
  }

  h3 {
    color: $titles;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    margin: 0;

    @media (min-width: 768px) {
      font-size: 24px;
    }

    @media (min-width: 801px) and (max-width: 1024px) {
      font-size: 15px;
    }
  }

  h5 {
    color: #14394c;
    opacity: 0.7;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: -0.3rem;

    @media (min-width: 768px) {
      font-size: 16px;
    }

    @media (min-width: 801px) and (max-width: 1024px) {
      font-size: clamp(0.7rem, 1vw, 0.7rem);
    }
  }

}


.info__chart__container__tablet {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  margin-top: 1rem;

  @media (min-width: 1440px) {
    margin-top: 4rem;
  }
}

.Informative__tablet {
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .totals__tablet {
    display: flex;
    gap: 4.5rem;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .column {
    display: flex;
    flex-direction: column;
  }

  .value {
    font-size: 2rem;
    line-height: 1.6rem;

    @media (min-width: 1000px) {
      font-size: 1.6rem;
      line-height: 1.3rem;
    }

    @media (min-width: 1440px) {
      font-size: 2.6rem;
      line-height: 1.85rem;
    }
  }

  .value__text {
    color: #14394C;
    opacity: 0.7;
    font-weight: 400;
    font-size: 1rem;
    margin-top: -.2rem;

    @media (min-width: 1440px) and (max-width: 2100px) {
      font-size: 1.5rem;
    }
  }
}

.PieArea__tablet {
  width: 100%;
  height: 100%;
  align-content: center;
  justify-content: center;
  display: flex;
  color: lightgray;
}






@media (max-width: 359px) {
  .middleCard {
    height: 45vh !important;
    width: 100% !important;
    margin-right: 0vh;
    margin-bottom: 1rem;
  }

  .CardHeader {
    padding: 0;
  }

  .CardHeader h3 {
    font-size: 10px;
  }

  .CardHeader h5 {
    font-size: 8px;
  }

}


@media (max-width: 360px) {


  .middleCard {
    height: 45vh !important;
    width: 100% !important;
    margin-right: 0vh;
    margin-bottom: 1rem;

  }

  .CardHeader {
    padding: 0.5rem !important;
  }

  .CardHeader h3 {
    font-size: 12px;
  }

  .CardHeader h5 {
    font-size: 6px;
  }


}

@media (min-width: 361px) and (max-width: 375px) {

  .middleCard {
    height: 50vh !important;
    width: 100% !important;
    margin-right: 0vh;
    margin-bottom: 1rem;
  }

  .CardHeader {
    padding: 0rem;
  }

  .CardHeader h3 {
    font-size: 16px;
  }

  .CardHeader h5 {
    font-size: 12px;
  }

  .AreaChartArea {
    margin-top: 0rem;
  }

}

@media (min-width: 376px) and (max-width: 390px) {

  .middleCard {
    height: 45vh !important;
    width: 100% !important;
    margin-right: 0vh;
    margin-bottom: 1rem;
  }

  .CardHeader {
    padding: 0;
  }

  .CardHeader h3 {
    font-size: 16px;
  }

  .CardHeader h5 {
    font-size: 12px;
  }


}

@media (min-width: 391px) and (max-width: 412px) {

  .middleCard {
    height: 45vh !important;
    width: 100% !important;
    margin-right: 0vh;
    margin-bottom: 1rem;
  }

  .CardHeader {
    padding: 0;
  }

  .CardHeader h3 {
    font-size: 16px;
  }

  .CardHeader h5 {
    font-size: 12px;
  }

  .AreaChartArea {
    margin-top: 0rem;
  }

}


@media (min-width: 413px) and (max-width: 414px) {

  .middleCard {
    height: 50vh !important;
    width: 100% !important;
    margin-right: 0vh;
    margin-bottom: 1rem;
  }

  .CardHeader {
    padding: 0;
  }

  .CardHeader h3 {
    font-size: 16px;
  }

  .CardHeader h5 {
    font-size: 12px;
  }

}






@media (min-width: 415px) and (max-width: 480px) {

  .middleCard {
    height: 45vh !important;
    width: 100% !important;
    margin-right: 0vh;
    margin-bottom: 1rem;
  }

  .CardHeader {
    padding: 0;
  }

  .CardHeader h3 {
    font-size: 18px;
  }

  .CardHeader h5 {
    font-size: 12px;
  }

}

@media (min-width: 481px) and (max-width: 599px) {

  .middleCard {
    height: 35vh !important;
    width: 100% !important;
    margin-right: 0vh;
    margin-bottom: 1rem;
  }

  .CardHeader {
    padding: 0;
  }

  .CardHeader h3 {
    font-size: 18px;
  }

  .CardHeader h5 {
    font-size: 12px;
  }

}



@media (min-width: 600px) and (max-width: 768px) {
  .middleCard {
    height: 42vh;
    width: 100% !important;
    margin-right: 0vh;
    margin-bottom: 1rem;


  }

  .CardHeader h3 {
    font-size: 20px;
  }

  .CardHeader h5 {
    font-size: 14px;
  }


}

@media (min-width: 769px) and (max-width: 800px) {
  .middleCard {
    height: 40vh;
    width: 100% !important;
    margin-right: 0vh;
    margin-bottom: 1rem;




  }

  .CardHeader h3 {
    font-size: 24px !important;
  }

  .CardHeader h5 {
    font-size: 16px !important;
  }


}

@media (min-width: 801px) and (max-width: 1024px) {






  .CardHeader h3 {
    font-size: clamp(1rem, 1.5vw, 1rem);


  }

  .CardHeader h5 {
    font-size: clamp(0.7rem, 1vw, 0.7rem);

  }


}

@media (min-width: 1025px) and (max-width: 1280px) {



  .CardHeader h3 {
    font-size: clamp(1rem, 1.5vw, 1.5rem);



  }

  .CardHeader h5 {
    font-size: clamp(0.7rem, 1vw, 0.8rem);

  }


}











@media (max-width: 1280px) and (max-height: 650px) {


  .CardHeader h3 {
    font-size: 14px;


  }

  .CardHeader h5 {
    font-size: 9px;

  }


}




@media (max-width: 1024px) and (min-height: 650px) and (max-height: 700px) {
  .middleCard {
    height: 38vh;



  }

  .CardHeader h3 {
    font-size: 11px;

  }

  .CardHeader h5 {
    font-size: 7px;

  }


}





@media (max-width: 900px) and (min-height: 650px)and (max-height: 700px) {


  .middleCard {
    height: 38vh;

    padding: 1rem;


  }

  .CardHeader h3 {
    font-size: 14px;

  }

  .CardHeader h5 {
    font-size: 9px;

  }


}


@media (max-width: 375px) and (min-height: 600px) and (max-height: 667px) {
  .middleCard {
    height: 60vh !important;
    width: 100% !important;

  }

  .CardHeader {
    height: 3rem;
    padding: 0.5rem;
  }

  .CardHeader h3 {
    font-size: 14px;


  }

  .CardHeader h5 {
    font-size: 9px;

  }





}


@media (max-width: 800px) and (max-height: 400px) {
  .middleCard {
    height: 80vh !important;
    width: 100% !important;
    margin-right: 0vh;
    padding: 1rem;
  }

  .CardHeader {
    height: 3rem;
  }

  .CardHeader h3 {
    font-size: 14px;


  }

  .CardHeader h5 {
    font-size: 9px;

  }




}


@media (min-width: 1024px) and (height: 1366px) {

  .middleCard {
    height: 42vh;



    padding: 0.5rem;
    // background-color: #806cdb;

  }

  .CardHeader h3 {
    font-size: 24px;

  }

  .CardHeader h5 {
    font-size: 16px;

  }


}

@media (max-width: 360px) and (max-height: 768px) {
  .middleCard {
    height: 42vh;

    padding: 0.5rem;
    // background-color: #806cdb;

  }

  .CardHeader h3 {
    font-size: clamp(1rem, 1.5vw, 1rem) !important;

  }

  .CardHeader h5 {
    font-size: 9px !important;

  }
}