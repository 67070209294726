@import '../../styles/variables';

// Add the following CSS reset rule at the beginning of your CSS file
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

// Add the following rule to remove any extra margin and padding from the body element
body {
  margin: 0;
  padding: 0;
}

// Your existing CSS rules go here
// ...



.container {
  display: flex;
  background-color: #F5F6F8;
  min-height: 100vh;
  
  background-position: 0px -70px ;

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 1.5rem;
    
  }

  .mainContainer {
    margin-top: 10rem;
    background-color: #fff;
    margin-bottom: 1.25rem;
    border-radius: 25px;
    overflow-x: auto;


    
    
    
  } 
  
}


  .header {
    padding: 2rem 2rem 0rem 2rem;
    //background-color: aqua;
    
    
    

    .topHeader {
      h2 {
        color: $gray-500;
        margin-bottom: 1.25rem;
        font-weight: 400;
        font-size: 1.25rem;
        
        
        
      }

      button {
        text-transform: none !important;
        padding: 10px;
        margin-right: 1.25rem;
        //background-color: chartreuse;
        
        
        
        
        
      }
      
    }
    .medidores{
      //display: none;
      //display: block !important;
      border-bottom: 1; 
      border-color: divider;
      
      
    }
  }
    .searchAndAddContainer {
    display: flex;
        justify-content: space-between;
        //background-color: aqua;
        padding: 22px !important;
        //background-color: aqua;
        //display: none !important;
        
        
        
        
        
    
    

    .formControle {
      padding: 8px 0px;
      //display: none !important;
      
      
      
    }

    .fixMuiInputBaseRoot {
      height: 40px;
      padding-left: 1rem;
      display: flex;
      align-items: center;
      width: 30vw !important;
      
      
      
      
    }

    input[type=text] {
      height: 40px;
      width: 30vw;
      padding: 0px 10px;
      box-sizing: border-box;
      border: none;
      border-radius: 5px;
      transition: 1s;
      outline: none;
      //display: none !important;
      
      
      
      
    }

    input[type=text]:focus {
      border: 1px solid $blue-100;
      //display: none !important;
      

      
    }

    button {
      background: linear-gradient(90deg, rgba(10,112,208,1) 24%, rgba(151,185,49,1) 100%, rgba(0,255,35,0.15303430079155678) 100%);
      width: 30vw;
      height: 40px;
      border-radius: 5px;
      border: none;
      color: $white;
      text-transform: uppercase;
      font-size: 0.8rem;
      
      
      
      
    }
  }

  .contentTitle {
    background-color: $blue-40;
    padding: 1rem 2rem !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(220, 224, 233, 0);
    //display: none !important;
    
    
    
    
    

    h3 {
      color: $gray-500;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 128.91%;
      background-color: rgba(220, 224, 233, 0);
      //display: none !important;
      
      
      
    }

    label{
      color: $gray-500;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 128.91%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      //display: none !important;
      
      
      
    }
  }



  .LoadingScreen {
    height: 200px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    //display: none !important;
    
    
  }

  .fixMuiInputBaseRootEditModel {
    height: 40px;
    width: 224px;
    display: flex;
    align-items: center;
    padding-left: 1rem !important;
    background-color: #fff;
    //display: none !important;
    
    
    
    
  }
 @media screen and (min-width: 0px) and (max-width: 930px) {

  
 }
  
  

@media screen and (min-width: 601px) and (max-width: 920px) {

  
}

@media screen and (min-width: 921px) and (max-width: 1150px) {

  
}

@media screen and (min-width: 1151px) and (max-width: 1550px) {

  
}


@media screen and (min-width: 1551px) and (max-width: 1920px) {

  
}



@media screen and (min-width: 1920px) and (max-width: 2560px){

  


}

@media screen and (min-width: 2561px) and (max-width: 4000px) {

  
}

  