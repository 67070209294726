@import '../../../styles/variables';

.smallerButton {
  background: linear-gradient(90deg, rgba(10,112,208,1) 24%, rgba(151,185,49,1) 100%, rgba(0,255,35,0.15303430079155678) 100%);
  width: 7rem;
  height: 2rem;
  border-radius: 5px;
  border: none;
  color: $white;
  text-transform: uppercase;
  font-size: 0.75rem;
  text-align: center;
}

.smallButton {
  background: linear-gradient(90deg, rgba(10,112,208,1) 24%, rgba(151,185,49,1) 100%, rgba(0,255,35,0.15303430079155678) 100%);
  width: 8rem;
  height: 2.6rem;
  border-radius: 5px;
  border: none;
  color: $white;
  text-transform: uppercase;
  font-size: 0.8rem;
  text-align: center;
}

.middleButton {
  background:#0a85cd;
      width: 15rem;
  width: 12rem;
  height: 2.6rem;
  border-radius: 5px;
  border: none;
  color: $white;
  text-transform: uppercase;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
   justify-content: center;
  text-align: center;
}

.largeButton {
  background: linear-gradient(90deg, rgba(10,112,208,1) 24%, rgba(151,185,49,1) 100%, rgba(0,255,35,0.15303430079155678) 100%);
  width: 18rem;
  height: 2.6rem;
  border-radius: 5px;
  border: none;
  color: $white;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
}

.smallOutlineButton {
  background-color: $white;
  width: 8rem;
  height: 2.6rem;
  border-radius: 5px;
  border: 1px solid $gray-50;
  color: $blue-500;
  text-transform: uppercase;
  font-size: 0.8rem;
  text-align: center;
}

.middleOutlineButton {
  background-color: $white;
  width: 12rem;
  height: 2.6rem;
  border-radius: 5px;
  border: 1px solid $gray-50;
  color: $blue-500;
  text-transform: uppercase;
  font-size: 0.8rem;
  text-align: center;
}

.largeOutlineButton {
  background-color: $white;
  width: 18rem;
  height: 2.6rem;
  border-radius: 5px;
  border: 1px solid $gray-50;
  color: $blue-500;
  text-transform: uppercase;
  text-align: center;
}


.smallLoading{
  color: #fff;
  height: 100%;
  width: 25%
}

.middleLoading{
  color: #fff;
  height: 100%;
  width: 20%
}

.largeLoading{
  color: #fff;
  height: 100%;
  width: 15%
}
.deleteButton{
  background: #fa4949;
  width: 8rem;
  height: 2.6rem;
  border-radius: 5px;
  border: none;
  color: $white;
  text-transform: uppercase;
  font-size: 0.8rem;
  text-align: center;
}

.middleOutlineButtonRed {
  background-color: $white;
  width: 12rem;
  height: 2.6rem;
  border-radius: 5px;
  border: 1px solid #fa4949;
  color: #fa4949;
  text-transform: uppercase;
  font-size: 0.8rem;
  text-align: center;
}