@import '../../styles/variables';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-image: url(../Signin//lampada.jpg);
  background-size: 350%;
  background-position: 0px 00px ;

  .ContentBox {
    width: 46rem;
    height: auto;
    background:white;
    padding: 2rem 4rem;
    display: flex;
    flex-direction: column;
    border: 0.5px solid $gray-50;
    box-shadow: 0px 4px 8px rgba(176, 190, 197, 0.3);
    border-radius: 15px;
  }

  .containerHeader {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    margin-bottom: 2.5rem;

    .containerTitle {
      display: flex;
      align-items: center;
      justify-content: center;

      h3 {
        color: $gray-100;
        text-transform: uppercase;
        font-weight: 500;
      }
    }
  }

  .PersonButton {
    margin-bottom: 2.5rem;
    border-bottom: 0.5px solid $gray-50;
  }

  .ActiveTabButton {
    border: none;
    background: transparent;
    padding: 8px 20px;
    border-bottom: 5px solid $blue-500;
    color: $blue-500;
    font-weight: 500;
  }

  .disabledTabButton {
    border: none;
    background: transparent;
    padding: 8px 20px;
    color: $gray-50;
    font-weight: 400;
  }

  .formTitleDP {
    margin-bottom: 1.25rem;

    h3 {
      color: $gray-500;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 1rem;
    }
  }

  .formTitleUC {
    h3 {
      color: $gray-500;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 1rem;
    }
  }

  .containerPersonInput {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;

    button {
      margin-top: 0.75rem;
    }
  }

  .InputOrganization {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.25rem;
  }

  .InputOrganizationUserdata {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }

  .ucContainerHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .containerButtons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }

  .buttonText {
    border: none;
    background: transparent;
    font-size: 0.8rem;
    text-decoration: none;
    color: $gray-100;
    font-weight: 400;
    text-transform: uppercase;
  }

  .dividerVertical {
    height: 12px;
    width: 1px;
    background: $gray-50;
    margin: 0 15px;
  }

  .dividerHorizontal {
    width: 100%;
    height: 1px;
    background: $gray-50;
    margin-bottom: 1.25rem;
    margin-top: 1.25rem;
  }

  .marginTop {
    margin-top: 1.5rem;
  }
}
.btnSnackBar{
  span {
    color: $white !important;
    text-decoration: underline;
  }
}
