@import "./variablesDatepicker";
@import "../variables";

.react-datepicker__header {
  text-align: center;
  background-color: #bddb6c;
  border-top-left-radius: 13px;
  padding: 8px 0;
  position: relative;
  border-bottom: none;
  color: $titles;
}
.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: 13px;
}

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: $datepicker__muted-color;
  font-weight: bold;
  font-size: 0.944rem;
}

.react-datepicker__day-names {
  margin-bottom: -8px;
  background-color: $white;
}

%navigation-chevron {
  border-color: $datepicker__muted-color;
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: "";
  display: block;
  height: 9px;
  position: absolute;
  top: 6px;
  width: 9px;

  &--disabled,
  &--disabled:hover {
    border-color: $datepicker__navigation-disabled-color;
    cursor: default;
  }
}

.react-datepicker__navigation-icon {
  position: relative;
  top: -1px;
  font-size: 20px;
  width: 0;

  &::before {
    @extend %navigation-chevron;
  }

  &--next {
    left: -2px;

    &::before {
      transform: rotate(45deg);
      left: -7px;
    }
  }

  &--previous {
    right: -2px;

    &::before {
      transform: rotate(225deg);
      right: -7px;
    }
  }
  react-datepicker-ignore-onclickoutside {
    border: none;
  }
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 0.3rem;
  border: 0.5px solid $button-Green !important;
  background-color: white !important;
  color: $titles;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__day--keyboard-selected:hover {
  border-radius: 0.3rem;
  border: 0.5px solid $button-Green !important;
  background-color: white !important;
  color: $titles;
}

// custom days

.react-datepicker__day-name {
  color: #14394c80;
}
.react-datepicker__day,
.react-datepicker__time-name {
  color: $titles;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  cursor: default;
  color: #8a9ca6;
}
