@import '../../../styles/variables';

$screen-laptop1024: 1024px;
$screen-laptop1280: 1280px;
$screen-laptop1440: 1440px;
$screen-4k: 2560px;

.content{
    padding-left: 10rem;
    padding-right: 10rem;
    //background-color: aquamarine;
    //width: 80vw !important;
    background-color: #D1DCE1;

}



.containerIcon {
    position: absolute;
    right: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  color: lightgray;
  //display: none;
  //ackground-color: #9b0000;
}



/* ----------- iPhone 6, 6S, 7 and 8 ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2) { 
    .content{
    max-width: 100vw;
    //background-color: bisque;
}
.caixa{
    //background-color: aqua;
}
}

/* Portrait */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) { 

}

/* Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) { 

}
