@import '../../../styles/variables';

.containerDatepicker{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  max-width: 48px;
  max-height: 48px;

}

.containerIcon{
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 100px;
  gap: 1rem;
  cursor: pointer;
  // margin-top: 0.3rem;
}
.circleIcon {
  background-color: #EAF2F6;
  padding: clamp(0.4rem, 1vw, 0.8rem); 
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  font-size: clamp(6px, 1.5vw, 10px); 
  color: $blue-500;
  width: clamp(40px, 6vw, 48px) !important; 
  height: clamp(40px, 6vw, 48px) !important; 
}




.containerIcon1{
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 100px;
  gap: 1rem;
  cursor: pointer;
  margin-top: 2rem;
  // margin-top: 0.3rem;
  z-index: 9999;
}
.circleIcon1 {
  background-color: #EAF2F6;
  padding: clamp(0.4rem, 1vw, 0.8rem); 
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  font-size: clamp(6px, 1.5vw, 10px); 
  color: $blue-500;
  width: clamp(40px, 6vw, 48px) !important; 
  height: clamp(40px, 6vw, 48px) !important; 
  z-index: 9999;
}



.reactDatepickerWrapper{
  input{
    display: none !important;
    
  }
}

.reactDatepickerCalendar{
  color: white;
  border-radius: 14px;
}



@media (min-width: 321px) and (max-width: 360px) {
  .containerIcon{
    
    margin-top: 0px;
  }
  .circleIcon {

    padding: 0.4rem !important; 
    
    width: 30px !important; 
    height: 30px !important; 
  }

  .circleIcon1 {

    padding: 0.4rem !important; 
    
    width: 30px !important; 
    height: 30px !important; 
  }

}

@media (min-width: 361px) and (max-width: 375px) {
  .containerIcon{
    
    margin-top: 0px;
  }
  .circleIcon {

    padding: 0.4rem !important; 
    
    width: 30px !important; 
    height: 30px !important; 
  }


  .circleIcon1 {

    padding: 0.4rem !important; 
    
    width: 30px !important; 
    height: 30px !important; 
  }

}



@media (min-width: 0px) and (max-width: 1280px) {
 
  .circleIcon {

    padding: clamp(0.4rem, 1vw, 0.6rem);
        width: clamp(30px, 6vw, 38px) !important;
        height: clamp(30px, 6vw, 38px) !important;
  }

  

}


