@import '../../styles/variables';

.imgG {
  width: 170px; 
  height: 160px; 
  margin-bottom: -20px;
  margin-top: -40px; 
}

 .imgPq {
    margin-bottom: 1rem;
    width: 56px;
    height: 79px;
 }

 

a {
  text-decoration: none;
  color: #000;
  font-weight: 600;
}

.help__word {
  font-size: 1.5rem;
  font-weight: 600;
  font-size: 16px;
  color: #FFFFFF;
}

// .help__icon {
//   fill: #F5F8EC;
//   width: 22px;
//   height: 22px;
// }

// .help__container {
//   position: absolute;
//   bottom: 35px;
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   z-index: 1000000;
// }



@media  (max-width: 1024px) {
  .imgG {
    width: 140px; 
    height: 120px; 
    margin-bottom: -20px;
    margin-top: -40px; 
  }
  
   .imgPq {
      margin-bottom: 1rem;
      width: 40px;
      height: 70px;
   }
}