@import '../../../styles/variables';

.InputSelect {
  background-color: $blue-10;

  div {
    height: 40px !important;
    background: $blue-10;
    outline: none;
    padding: 0px !important;
    box-sizing: border-box;
    border-radius: 0.3rem;
    font-size: 0.9rem;
  }
}


.fixMuiInputBaseRootEditModel {
  height: 40px;
  display: flex;
  align-items: center;
}

@media screen and (min-width: 0px) and (max-width: 445px) {

  .associarUnid{
    //width: 100% !important;
    //background-color: #d32f2f;
    min-width: 310px;
    min-height: 130px;
  }

  .editarBox{

    //background-color: #d32f2f;
    width: 100%;
    
  }
}

@media screen and (min-width: 446px) and (max-width: 2445px) {

  .associarUnid{
    display: flex;
    //width: 100% !important;
    //background-color: #d32f2f;
    width: 400px;
    height: 150px;
    
  }

  .editarBox{

    //background-color: #d32f2f;
    width: 100%;
    
  }
}