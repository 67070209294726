@import '../../../../styles/variables';

.container {
  display: flex;
  flex-direction: column;

  .section {
    .itemContainer {
      display: flex;
      flex-direction: column;
      padding-bottom: 1rem !important;
    }

    .titletable {
      display: grid;
      grid-template-columns: 30px 1fr 1fr;
      background-color: $blue-25;
      padding: 0rem 2rem !important;

      .contentHeader {
        padding: 0.5rem 0rem;

        h5 {
          color: $gray-500;
          font-weight: 400;
          font-size: 0.8rem;
          text-align: left;
        }
      }
    }

    .actiontable {
      padding: 0rem 2rem !important;
      background-color: $blue-60;
      display: grid;
      grid-template-columns: 1fr 100px;
      align-items: center;

      .contentHeader {
        padding: 0.5rem 0rem;

        h5 {
          color: $gray-500;
          font-weight: 400;
          font-size: 0.8rem;
          text-align: left;
        }

        .deletItens {
          text-transform: uppercase;
          font-size: 0.7rem;
          cursor: pointer;
        }
      }
    }

    .mainContent {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding: 1rem 2rem !important;
      align-items: flex-start;
      //background-color: aqua;

      .contentText {
        h4 {
          color: $gray-500;
          font-weight: 400;
          font-size: 1rem;
        }

        p {
          color: $gray-100;
          font-weight: 400;
          font-size: 0.8rem;
        }
      }
    }

    .contentBox3 {
      .contentItem {
        display: grid;
        align-items: center;
        justify-content: flex-end;
        background-color: $white;
        grid-template-columns: 40px 40px;
        gap: 0px;
        background-color: rgba(220, 224, 233, 0);
        //background-color: aqua;

        button {
          border: none;
          background: transparent;
          color: $red-200;
        }

        button:first-child {
          color: $blue-500;
        }
      }
    }
  }

  .containerButtons {
    width: 100%;
    background-color: $white;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 0rem 2rem 1rem 2rem !important;
    border-radius: 15px, 15px;
    margin-bottom: 1.25rem;

    button {
      width: 170px;
      height: 40px;
      padding: 0.25rem;
      border: none;
      background: $global-gradient-blue-green;
      border-radius: 12px;
      color: $white;
      font-weight: 400;
      font-size: 14px;
      margin-bottom: 1rem;
    }
  }

  .dividerContainer {
    padding: 0rem 2rem 1rem 2rem !important;
  }

  .dividerHorizontal {
    width: 100%;
    height: 1px;
    margin-top: 10px;
    background-color: $gray-50;
  }

}

@media screen and (min-width: 0px) and (max-width: 1000px){

  .contentItem{
      //display: none !important;
      font-size: 13px !important;
      //display: none !important;
      //background-color: aqua !important;
      width: 20vw !important;
    }
    .contentBox3 .contentItem {
    //background-color: aqua;
    position: absolute;
    right: 20px;
  }
}