.legendContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: 6vh;
    width: 42vw;
    padding: 1rem;
}

.chartLegend {
    position: absolute;
    top: 0.5rem;
    right: 6.2rem;
    width: 37vw;
    display: flex;
    flex-wrap: wrap;
    max-height: 6rem;
    overflow-y: auto;
    padding: 0.5rem;
}

.chartLegendItem {
    display: flex;
    align-items: center;
    margin-right: 1.5rem;
    color: black;
    cursor: pointer;
    margin-top: 2px;
}

.chartLegendColor {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
}

.chartLegendLabel {
    font-size: clamp(8px, 1vw, 16px);
    color: #2566ff;
    opacity: 0.7 !important;
    font-family: Inter;
    font-weight: 500;
    position: relative;
    transition: opacity 0.3s ease; /* Transição suave para a opacidade */
}

/* Adiciona a linha azul apenas quando o item não está clicado */
.chartLegendLabel::after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #2566ff;
    opacity: 0.7;
    margin-top: 1px;
    position: absolute;
    top: 80%;
    left: 0;
    transition: opacity 0.3s ease; /* Transição suave para a linha */
}

/* Quando o item é clicado, a linha desaparece */
.clickedLabel::after {
    opacity: 0;
}

/* Opacidade modificada quando o item é clicado */
.clickedLabel {
    opacity: 0.7;
}

.hiddenLegendItem {
    opacity: 0.5; /* Ajuste a opacidade conforme necessário */
}

.hiddenLegendLabel {
    color: #999999;
}

.hiddenLegendColor {
    color: #999999;
}

.hiddenLegendLabel::after {
    opacity: 0; /* Opacidade para legendas ocultas */
}

.clickedLabel {
    opacity: 0.7;
}

.specialLegend {
    position: absolute;
    top: -14vh;
    right: 2.5rem;
    width: 13vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.5rem;
}

.specialLegendLabel {
    text-decoration: none !important;
    color: #000000 !important;
    opacity: 0.7;
    font-size: clamp(0.8rem, 0.6rem + 1vw, 1rem);
    padding: 0.2rem;
}

/* MEDIA QUERIES */

@media (max-width: 320px) {
    .legendContainer {
        height: 10vh;
        width: 75vw;
    }
    .chartLegend {
        max-height: 8rem;
        top: 0rem !important;
        right: -1rem !important;
        width: 70vw;
    }
    .chartLegendColor {
        width: 4px;
        height: 4px;
        margin-right: 5px;
    }
    .chartLegendLabel {
        font-size: 0.6rem;
    }
    .chartLegendItem {
        margin-right: 10px;
    }

    .specialLegend {
        display: none;
    }
    .specialLegendLabel {
        font-size: 0.5rem !important;
    }
}

@media (min-width: 321px) and (max-width: 360px) {
    .legendContainer {
        height: 10vh;
        width: 75vw;
    }
    .specialLegend {
        position: absolute;
        top: -15rem !important;
        right: -2rem !important;
        width: 42vw;
    }
    .chartLegendColor {
        width: 6px;
        height: 6px;
        margin-right: 2px !important;
        margin-bottom: 0rem !important;
    }
    .chartLegendItem {
        margin-right: 7px !important;
    }
    .chartLegend {
        max-height: auto;
        top: -1rem !important;
        right: -1.5rem;
        width: 76vw !important;
        align-items: center;
        justify-content: center;
    }
    .chartLegendLabel {
        font-size: 0.7rem !important;
        margin-bottom: 0.2rem !important;
    }

    .specialLegendLabel {
        font-size: 0.7rem !important;
        margin-bottom: 0.2rem !important;
    }
}

@media (min-width: 361px) and (max-width: 375px) {
    .legendContainer {
        height: 10vh;
        width: 75vw;
    }
    .specialLegend {
        position: absolute;
        top: -15rem;
        right: -1rem;
        width: 38vw;
    }
    .chartLegendColor {
        width: 4px;
        height: 4px;
        margin-right: 5px;
        margin-bottom: 0.5rem;
    }
    .chartLegendItem {
        margin-right: 10px;
    }
    .chartLegend {
        max-height: 8rem;
        top: -0.5rem;
        right: -1.5rem;
        width: 77vw;
        align-items: center;
        justify-content: center;
    }
    .chartLegendLabel {
        font-size: 0.6rem;
        margin-bottom: 0.5rem;
    }

    .specialLegendLabel {
        font-size: 0.6rem;
        margin-bottom: 0.5rem;
    }
}

@media (min-width: 376px) and (max-width: 390px) {
    .legendContainer {
        height: 10vh;
        width: 75vw;
    }
    .specialLegend {
        position: absolute;
        top: -25vh;
        right: -1rem;
        width: 38vw;
    }
    .chartLegendColor {
        width: 4px;
        height: 4px;
        margin-right: 5px;
        margin-bottom: 0.4rem;
    }
    .chartLegendItem {
        margin-right: 10px;
    }
    .chartLegend {
        max-height: 8rem;
        top: -0.5rem;
        right: -1.5rem;
        width: 78vw;
        align-items: center;
        justify-content: center;
    }
    .chartLegendLabel {
        font-size: 0.7rem;
        margin-bottom: 0.4rem;
    }

    .specialLegendLabel {
        font-size: 0.7rem;
        margin-bottom: 0.4rem;
    }
}
@media (min-width: 391px) and (max-width: 412px) {
    .legendContainer {
        height: 10vh;
        width: 75vw;
    }
    .specialLegend {
        position: absolute;
        top: -25vh;
        right: -2rem;
        width: 38vw;
    }
    .chartLegendColor {
        width: 4px;
        height: 4px;
        margin-right: 5px;
        margin-bottom: 0.5rem;
    }
    .chartLegendItem {
        margin-right: 10px;
    }
    .chartLegend {
        max-height: 8rem;
        top: -0.5rem;
        right: -1.5rem;
        width: 78vw;
        align-items: center;
        justify-content: center;
    }
    .chartLegendLabel {
        font-size: 0.7rem;
        margin-bottom: 0.5rem;
    }

    .specialLegendLabel {
        font-size: 0.7rem;
        margin-bottom: 0.5rem;
    }
}

@media (min-width: 413px) and (max-width: 414px) {
    .legendContainer {
        height: 10vh;
        width: 75vw;
    }
    .specialLegend {
        position: absolute;
        top: -27vh;
        right: -2rem;
        width: 38vw;
    }
    .chartLegendColor {
        width: 4px;
        height: 4px;
        margin-right: 5px;
        margin-bottom: 0.5rem;
    }
    .chartLegendItem {
        margin-right: 12px;
    }
    .chartLegend {
        max-height: 7rem;
        top: -0.5rem;
        right: -2rem;
        width: 79vw;
        align-items: center;
        justify-content: center;
    }
    .chartLegendLabel {
        font-size: 0.7rem;
        margin-bottom: 0.5rem;
    }

    .specialLegendLabel {
        font-size: 0.7rem;
        margin-bottom: 0.5rem;
    }
}

@media (min-width: 415px) and (max-width: 480px) {
    .legendContainer {
        height: 11vh;
        width: 75vw;
    }
    .specialLegend {
        position: absolute;
        top: -14rem;
        right: -3rem;
        width: 35vw;
    }
    .chartLegendColor {
        width: 4px;
        height: 4px;
        margin-right: 6px;
        margin-bottom: 0.2rem;
    }
    .chartLegendItem {
        margin-right: 15px;
    }
    .chartLegend {
        max-height: 8rem;
        top: -0.5rem;
        right: -2rem;
        width: 78vw;
        align-items: center;
        justify-content: center;
    }
    .chartLegendLabel {
        font-size: 0.75rem;
        margin-bottom: 0.2rem;
    }

    .specialLegendLabel {
        font-size: 0.6rem;
        margin-bottom: 0.2rem;
    }
}
@media (min-width: 481px) and (max-width: 600px) {
    .legendContainer {
        height: 10vh;
        width: 75vw !important;
    }
    .specialLegend {
        position: absolute;
        top: -14vh;
        right: -3rem;
        width: 30vw;
    }
    .chartLegendColor {
        width: 6px;
        height: 6px;
        margin-right: 4px;
        margin-bottom: 0.4rem;
    }

    .chartLegend {
        max-height: 8rem;
        top: 0rem;
        right: -1rem;
        width: 78vw;
        align-items: center;
        justify-content: center;
    }
    .chartLegendLabel {
        font-size: 0.75rem;
        margin-bottom: 0.4rem;
    }

    .specialLegendLabel {
        font-size: 0.75rem;
        margin-bottom: 0.4rem;
    }
}

@media (min-width: 601px) and (max-width: 768px) {
    .legendContainer {
        height: 10vh;
        width: 75vw;
    }
    .specialLegend {
        position: absolute;
        top: -15vh;
        right: -2rem;
        width: 25vw;
        margin-bottom: 0.4rem;
    }
    .chartLegendColor {
        width: 6px;
        height: 6px;
        margin-right: 6px;
        margin-bottom: 0.4rem;
    }

    .chartLegend {
        max-height: 7rem;
        top: 0rem;
        right: 0rem;
        width: 75vw;
        align-items: center;
        justify-content: center;
    }
    .chartLegendLabel {
        font-size: 0.8rem;
        margin-bottom: 0.4rem;
    }

    .specialLegendLabel {
        font-size: 0.8rem;
    }
}

@media (min-width: 769px) and (max-width: 800px) {
    .legendContainer {
        height: 10vh;
        width: 75vw;
    }
    .specialLegend {
        position: absolute;
        top: -12vh;
        right: -1.4rem;
        width: 23vw;
    }

    .chartLegendColor {
        width: 6px;
        height: 6px;
        margin-right: 6px;
        margin-bottom: 0.8rem;
    }
    .chartLegend {
        max-height: 7rem;

        top: 0rem;
        right: 0rem;
        width: 75vw;
        align-items: center;
        justify-content: center;
    }

    .chartLegendLabel {
        font-size: 0.8rem;
        margin-bottom: 0.8rem;
    }

    .specialLegendLabel {
        font-size: 0.8rem;
    }
}

@media (min-width: 801px) and (max-width: 860px) {
    .legendContainer {
        width: 45vw !important;
    }
    .chartLegend {
        width: 40vw;
        right: 2rem !important;
        top: -0.7rem !important;
    }

    .chartLegendLabel::after {
        height: 0.5px;
    }

    .chartLegendItem {
        margin-right: 5px;
    }

    .chartLegendColor {
        margin-right: 5px;
        margin-bottom: 0.1rem !important;
        width: 4px;
        height: 4px;
    }

    .specialLegend {
        right: 3rem !important;
        width: 9rem;
    }

    .specialLegendLabel {
        font-size: 0.6rem !important;
        margin-bottom: 0.1rem !important;
    }
    .chartLegendLabel {
        font-size: 0.7rem !important;
        margin-bottom: 0.1rem !important;
    }
}

@media (min-width: 861px) and (max-width: 1024px) {
    .legendContainer {
        width: 45vw !important;
    }
    .chartLegend {
        width: 40vw;
        right: 2rem !important;
        top: -0.7rem !important;
    }

    .chartLegendLabel::after {
        height: 0.5px;
    }

    .chartLegendItem {
        margin-right: 5px;
    }

    .chartLegendColor {
        margin-right: 5px;
        margin-bottom: 0.1rem !important;
        width: 4px;
        height: 4px;
    }

    .specialLegend {
        right: 2rem !important;
        width: 9rem;
    }

    .specialLegendLabel {
        font-size: 0.6rem !important;
        margin-bottom: 0.1rem !important;
    }
    .chartLegendLabel {
        font-size: 0.7rem !important;
        margin-bottom: 0.1rem !important;
    }
}

@media (min-width: 1025px) and (max-width: 1280px) {
    .chartLegend {
        width: 37vw;
        right: 3rem;
        top: -1rem;
        max-height: 5rem;
    }

    .chartLegendItem {
        margin-right: 15px;
    }

    .chartLegendColor {
        margin-right: 5px;
        margin-bottom: 0.1rem !important;
    }

    .specialLegend {
        right: 1rem;
        width: 10rem;
    }

    .specialLegendLabel {
        font-size: 0.6rem;
        margin-bottom: 0.1rem;
    }
    .chartLegendLabel {
        font-size: 0.7rem !important;
        margin-bottom: 0.1rem;
    }
}

@media (min-width: 1281px) and (max-width: 1366px) {
    .chartLegend {
        width: 37vw;
        right: 3.5rem;
        top: -0.5rem;
    }

    .chartLegendItem {
        margin-right: 15px;
    }

    .chartLegendColor {
        margin-right: 5px;
        margin-bottom: 0.2rem;
    }

    .specialLegend {
        right: 0rem;
    }

    .specialLegendLabel {
        font-size: 0.65rem;
        margin-bottom: 0.2rem;
    }
    .chartLegendLabel {
        font-size: 0.65rem;
        margin-bottom: 0.2rem;
    }
}

@media (min-width: 1367px) and (max-width: 1440px) {
    .legendContainer {
        height: auto;
    }
    .chartLegend {
        width: 39vw;
        right: 2rem;
        top: -0.5rem;
    }

    .chartLegendItem {
        margin-right: 15px;
    }

    .chartLegendColor {
        margin-right: 5px;
        margin-bottom: 0.2rem;
    }

    .specialLegend {
        right: 3rem;
    }

    .specialLegendLabel {
        font-size: 0.7rem;
        margin-bottom: 0.2rem;
    }
    .chartLegendLabel {
        font-size: 0.7rem;
        margin-bottom: 0.2rem;
    }
}

@media (min-width: 1441px) and (max-width: 1680px) {
    .chartLegend {
        width: 37vw;
        right: 4rem;
        top: -0.5rem;
    }

    .chartLegendItem {
        margin-right: 12px;
    }

    .chartLegendColor {
        margin-right: 5px;
        margin-bottom: 0.2rem;
    }

    .specialLegend {
        right: 1rem;
    }

    .specialLegendLabel {
        font-size: 0.8rem;
        margin-bottom: 0.2rem;
    }
    .chartLegendLabel {
        font-size: 0.8rem;
        margin-bottom: 0.2rem;
    }
}

@media (min-width: 1681px) and (max-width: 1905px) {
    .chartLegend {
        width: 38vw;
        top: -0.6rem;
        right: 5rem;
    }
    .specialLegend {
        right: 2rem;
    }

    .specialLegendLabel {
        font-size: 0.8rem;
        margin-bottom: 0.2rem;
    }
    .chartLegendLabel {
        font-size: 0.8rem;
        margin-bottom: 0.2rem;
    }
}

@media (min-width: 1906px) and (max-width: 1919px) {
    .chartLegend {
        width: 38vw;
        margin-top: 0rem;
    }
}

@media (min-width: 1921px) and (max-width: 2300px) {
    .chartLegend {
        width: 38vw;
        margin-top: 0rem;
    }

    .specialLegend {
        /* display: none; */
        right: 1rem;
    }

    .specialLegendLabel {
        font-size: 0.9rem;
        margin-bottom: 0.2rem;
    }
    .chartLegendLabel {
        font-size: 0.9rem;
        margin-bottom: 0.2rem;
    }
}

@media (min-width: 2301px) and (max-width: 3000px) {
    .chartLegend {
        width: 38vw;
        margin-top: 0rem;
    }

    .specialLegend {
        display: none;
        /* right: 4rem; */
    }

    .specialLegendLabel {
        font-size: 0.9rem;
        margin-bottom: 0.2rem;
    }
    .chartLegendLabel {
        font-size: 0.9rem;
        margin-bottom: 0.2rem;
    }
}

@media (min-width: 3001px) and (max-width: 7000px) {
    .chartLegend {
        width: 38vw;
        margin-top: 0rem;
    }

    .specialLegend {
        display: none;
    }
}

@media (max-width: 1280px) and (max-height: 600px) {
    .chartLegend {
        top: -1rem;
        width: 39vw;
        right: 0rem;
    }
    .specialLegend {
        top: -18vh;
        right: -3rem;
    }
    .specialLegendLabel {
        font-size: 8px;
    }

    .chartLegendItem {
        margin-right: 10px;
    }
    .chartLegendColor {
        width: 5px;
        height: 5px;
    }

    .chartLegendLabel {
        font-size: 8px;
    }
}

@media (min-width: 1000px) and (max-width: 1024px) and (max-height: 700px) {
    .legendContainer {
        width: 40vw;
    }
    .chartLegend {
        top: -1rem !important;
        width: 38vw;
        right: -1rem;
    }

    .chartLegendColor {
        margin-bottom: 0rem;
        width: 4px;
        height: 4px;
    }
    .specialLegend {
        top: -18vh;
        right: -1rem;
    }
    .specialLegendLabel {
        font-size: 7px !important;
        margin-bottom: 0rem;
    }

    .chartLegendLabel {
        font-size: 7px !important;
        margin-bottom: 0rem !important;
    }
}

@media (min-width: 700px) and (max-width: 900px) and (min-height: 400px) and (max-height: 700px) {
    .legendContainer {
        width: 40vw;
    }
    .chartLegend {
        top: -1.5rem !important;
        width: 38vw;
        right: -1rem;
    }

    .chartLegendColor {
        margin-bottom: 0rem;
        width: 4px;
        height: 4px;
    }
    .specialLegend {
        top: -5rem !important;
        right: -1rem;
    }
    .specialLegendLabel {
        font-size: 6px !important;
        margin-bottom: 0rem;
    }

    .chartLegendLabel {
        font-size: 6px !important;
        margin-bottom: 0rem !important;
    }
}

@media (max-width: 1024px) and (max-height: 450px) {
    .legendContainer {
        width: 40vw;
    }
    .chartLegend {
        top: -1.4rem !important;
        width: 38vw;
        right: -1rem;
    }

    .chartLegendColor {
        margin-bottom: 0rem !important;
        width: 3px;
        height: 3px;
    }
    .specialLegend {
        top: -20vh;
        right: 2rem !important;
    }
    .specialLegendLabel {
        font-size: 6px !important;
        margin-bottom: 0rem !important;
    }

    .chartLegendLabel {
        font-size: 6px !important;
        margin-bottom: 0rem !important;
    }
}

@media (max-width: 1024px) and (min-height: 400px) and (max-height: 450px) {
    .legendContainer {
        width: 40vw;
    }
    .chartLegend {
        top: -1.5rem !important;
        width: 38vw;
        right: -1rem;
    }

    .chartLegendColor {
        margin-bottom: 0rem !important;
        width: 3px;
        height: 3px;
    }
    .specialLegend {
        top: -4.5rem !important;
        right: 1rem !important;
    }
    .specialLegendLabel {
        font-size: 6px !important;
        margin-bottom: 0rem !important;
    }

    .chartLegendLabel {
        font-size: 6px !important;
        margin-bottom: 0rem !important;
    }
}

@media (min-width: 900px) and (max-width: 1024px) and (min-height: 451px) and (max-height: 550px) {
    .legendContainer {
        width: 40vw;
    }
    .chartLegend {
        top: -1rem !important;
        width: 38vw;
        right: -1rem;
    }

    .chartLegendColor {
        margin-bottom: 0rem !important;
        width: 3px;
        height: 3px;
    }
    .specialLegend {
        top: -5rem !important;
        right: -0.5rem !important;
    }
    .specialLegendLabel {
        font-size: 6px !important;
        margin-bottom: 0rem !important;
    }

    .chartLegendLabel {
        font-size: 6px !important;
        margin-bottom: 0rem !important;
    }
}

@media (min-width: 1000px) and (max-width: 1200px) and (min-height: 551px) and (max-height: 650px) {
    .legendContainer {
        width: 40vw;
    }
    .chartLegend {
        top: -0.5rem !important;
        width: 38vw;
        right: -1rem;
    }

    .chartLegendColor {
        margin-bottom: 0rem !important;
        width: 3px;
        height: 3px;
    }
    .specialLegend {
        top: -4.5rem !important;
        right: -4rem !important;
    }
    .specialLegendLabel {
        font-size: 8px !important;
        margin-bottom: 0rem !important;
    }

    .chartLegendLabel {
        font-size: 8px !important;
        margin-bottom: 0rem !important;
    }
}
@media (max-width: 1024px) and (max-height: 250px) {
    .legendContainer {
        width: 40vw;
    }
    .chartLegend {
        top: -1.5rem !important;
        width: 38vw;
        right: -1rem;
    }

    .chartLegendColor {
        margin-bottom: 0rem !important;
        width: 3px;
        height: 3px;
    }
    .specialLegend {
        top: -20vh;
        right: -5rem !important;
    }
    .specialLegendLabel {
        font-size: 5px !important;
        margin-bottom: 0rem !important;
    }

    .chartLegendLabel {
        font-size: 5px !important;
        margin-bottom: 0rem !important;
    }
}

@media (max-width: 900px) and (min-height: 650px) and (max-height: 700px) {
    .legendContainer {
        width: 80vw;
    }
    .chartLegend {
        top: -1.5rem;
        width: 80vw;
        right: -3rem;
    }

    .chartLegendColor {
        margin-bottom: 0rem;
    }
    .specialLegend {
        top: -6rem;
        right: -7rem;
    }
    .specialLegendLabel {
        font-size: 6px;
        margin-bottom: 0rem;
    }

    .chartLegendLabel {
        font-size: 6px;
        margin-bottom: 0rem;
    }
}

@media (width: 375px) and (height: 667px) {
    .legendContainer {
        height: 10vh;
        width: 75vw !important;
    }

    .specialLegend {
        top: -15rem !important;
        right: -2rem;
    }
    .legendContainer {
        height: 10vh;
        width: 75vw;
    }

    .chartLegendColor {
        width: 4px;
        height: 4px;
        margin-right: 5px;
        margin-bottom: 0.5rem !important;
    }
    .chartLegendItem {
        margin-right: 10px;
    }
    .chartLegend {
        top: 0rem !important;
        right: -1.5rem;
        width: 77vw;
        align-items: center;
        justify-content: center;
        max-height: 7rem;
    }
    .chartLegendLabel {
        font-size: 0.7rem !important;
        margin-bottom: 0.5rem !important;
    }

    .specialLegendLabel {
        font-size: 0.7rem;
        margin-bottom: 0.5rem !important;
    }
}

@media (max-width: 800px) and (min-height: 200px) and (max-height: 300px) {
    .legendContainer {
        height: 10vh;
        width: 75vw;
    }
    .specialLegend {
        position: absolute;
        top: -6rem;
        right: -5rem !important;
        width: 35vw;
    }
    .chartLegendColor {
        width: 6px;
        height: 6px;
        margin-right: 6px;
        margin-bottom: 0.5rem;
    }
    .chartLegendItem {
        margin-right: 15px;
    }
    .chartLegend {
        top: -1rem;
        right: -3rem;
        width: 78vw;
        align-items: center;
        justify-content: center;
    }
    .chartLegendLabel {
        font-size: 8px;
        margin-bottom: 0.5rem;
    }

    .specialLegendLabel {
        font-size: 8px;
        margin-bottom: 0.5rem;
    }
}

@media (max-width: 800px) and (min-height: 301px) and (max-height: 400px) {
    .legendContainer {
        height: 10vh;
        width: 75vw;
    }
    .specialLegend {
        position: absolute;
        top: -7rem;
        right: -8rem !important;
        width: 35vw;
    }
    .chartLegendColor {
        width: 6px;
        height: 6px;
        margin-right: 6px;
        margin-bottom: 0.5rem;
    }
    .chartLegendItem {
        margin-right: 15px;
    }
    .chartLegend {
        top: -1rem;
        right: -3rem;
        width: 78vw;
        align-items: center;
        justify-content: center;
    }
    .chartLegendLabel {
        font-size: 8px;
        margin-bottom: 0.5rem;
    }

    .specialLegendLabel {
        font-size: 8px;
        margin-bottom: 0.5rem;
    }
}

@media (max-width: 500px) and (max-height: 300px) {
    .legendContainer {
        height: 10vh;
        width: 75vw;
    }
    .specialLegend {
        position: absolute;
        top: -5rem;
        right: -4rem;
        width: 35vw;
    }
    .chartLegendColor {
        width: 3px;
        height: 3px;
        margin-right: 6px;
        margin-bottom: 0.1rem;
    }
    .chartLegendItem {
        margin-right: 15px;
    }
    .chartLegend {
        top: -1.5rem;
        right: -3rem;
        width: 78vw;
        align-items: center;
        justify-content: center;
    }
    .chartLegendLabel {
        font-size: 6px;
        margin-bottom: 0.1rem;
    }

    .specialLegendLabel {
        font-size: 6px;
        margin-bottom: 0.1rem;
    }
}
