@import "../../../../../styles/variables";

.largeCard {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 25px; 
  padding: 1rem; 
  height: 41vh; /* Altura em viewport para adaptar ao tamanho da tela */
  width: 100%!important;
  position: relative;
  margin-top: 2rem;
  overflow:visible; /* Garante que o conteúdo fique dentro dos limites */
}




.containerIcon {
  display: flex;
  flex-direction: row;
}

.buttonCalendarContainer {
  display: flex;
  align-items: center;
}


.CardHeader {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0.5rem 1.5rem; 

  .containerTitle {
    display: flex;
    flex-direction: column;  
  }

  h3 {
    color: $titles;
    font-style: normal;
    font-weight: 700;
    font-size: clamp(1rem, 1.5vw, 1.5rem); 
    margin: 0; 
  }

  h5 {
    color: #14394c;
    opacity: 0.7;
  font-family: Inter;
  font-size: clamp(0.7rem, 1vw, 1rem);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
    margin-top: -0.3rem;
  }
}


.BarChartArea {
  width: 100%;
  align-content: center;
  justify-content: center;
  color: lightgray;
}

.containerIcon{
  display: flex;
}















@media (max-width: 359px) {

  .containerIcon {
    flex-direction: column; /* Itens em coluna para telas menores */
   
    
  }

  .buttonCalendarContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    // margin-right: 1.5rem;

    margin-top: 10px;
  }

}


@media (width: 360px) {

  .containerIcon {
    flex-direction: column; /* Itens em coluna para telas menores */
   
    
  }

  .buttonCalendarContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-right: 1.5rem;

    margin-top: 10px;
  }


}

@media (min-width: 361px) and (max-width: 375px) {

  .containerIcon {
    flex-direction: column; /* Itens em coluna para telas menores */
   
    
  }

  .buttonCalendarContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    // margin-right: 1.5rem;

    margin-top: 10px;
  }


}
@media (min-width: 376px) and (max-width: 390px) {

  .containerIcon {
    flex-direction: column; /* Itens em coluna para telas menores */
   
    
  }

  .buttonCalendarContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    // margin-right: 1.5rem;

    margin-top: 10px;
  }


}


@media (min-width: 391px) and (max-width: 412px) {

  .containerIcon {
    flex-direction: column; /* Itens em coluna para telas menores */
   
    
  }

  .buttonCalendarContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    // margin-right: 1.5rem;

    margin-top: 10px;
  }


}


@media (min-width: 413px) and (max-width: 414px) {

  .containerIcon {
    flex-direction: column; /* Itens em coluna para telas menores */
   
    
  }

  .buttonCalendarContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    // margin-right: 1.5rem;

    margin-top: 10px;
  }
}






@media (min-width: 415px) and (max-width: 480px) {


   .containerIcon {
    flex-direction: column; /* Itens em coluna para telas menores */
   
    
  }

  .buttonCalendarContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    // margin-right: 1.5rem;

    margin-top: 10px;
  }
}

@media (min-width: 481px) and (max-width: 600px) {

  .containerIcon {
    flex-direction: column; /* Itens em coluna para telas menores */
   
    
  }

  .buttonCalendarContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    // margin-right: 1.5rem;

    margin-top: 10px;
  }
}




@media (max-width: 359px) {
  .largeCard{
    height: 42vh !important;
    width: 100%!important; 
    margin-left: 0vh;
  }

  .CardHeader {
    padding: 0; 
}

.CardHeader h3{
  font-size: 10px;
}
.CardHeader h5{
  font-size: 8px;
}

}


@media (max-width: 360px) {


.largeCard{
    height: 38vh !important;
    width: 100%!important; 
    margin-left: 0vh;
    
  }

  .CardHeader {
    padding: 0.5rem !important;
}

.CardHeader h3{
  font-size: 12px;
}
.CardHeader h5{
  font-size: 6px;
}



}

@media (min-width: 361px) and (max-width: 375px) {

  .largeCard{
    height: 42vh !important;
    width: 100%!important; 
    margin-left: 0vh;
  }

  .CardHeader {
    padding: 0rem;
}

.CardHeader h3{
  font-size: 16px;
}
.CardHeader h5{
  font-size: 12px;
}


}
@media (min-width: 376px) and (max-width: 390px) {

  .largeCard{
    height: 42vh !important;
    width: 100%!important; 
    margin-left: 0vh;
  }

  .CardHeader {
    padding: 0; 
}

.CardHeader h3{
  font-size: 16px;
}
.CardHeader h5{
  font-size: 12px;
}


}

@media (min-width: 391px) and (max-width: 412px) {

  .largeCard{
    height: 42vh !important;
    width: 100%!important; 
    margin-left: 0vh;
  }

  .CardHeader {
    padding: 0; 
}

.CardHeader h3{
  font-size: 16px;
}
.CardHeader h5{
  font-size: 12px;
}

}


@media (min-width: 413px) and (max-width: 414px) {

  .largeCard{
    height: 42vh !important;
    width: 100%!important; 
    margin-left: 0vh;
  }

  .CardHeader {
    padding: 0; 
}

.CardHeader h3{
  font-size: 16px;
}
.CardHeader h5{
  font-size: 12px;
}


}






@media (min-width: 415px) and (max-width: 480px) {

  .largeCard{
    height: 42vh !important;
    width: 100%!important; 
    margin-left: 0vh;
  }

  .CardHeader {
    padding: 0; 
}

.CardHeader h3{
  font-size: 18px;
}
.CardHeader h5{
  font-size: 12px;
}


}

@media (min-width: 481px) and (max-width: 599px) {

  .largeCard{
    height: 35vh !important;
    width: 100%!important; 
    margin-left: 0vh;
  }

  .CardHeader {
    padding: 0; 
}

.CardHeader h3{
  font-size: 18px;
}
.CardHeader h5{
  font-size: 12px;
}


}



@media (min-width: 600px) and (max-width: 768px) {
  .largeCard{
    height: 42vh; 
    width: 100%!important;  
    margin-left: 0vh;
    margin-top: 2rem;
    
    
  }

  .CardHeader h3{
    font-size: 20px;
  }
  .CardHeader h5{
    font-size: 14px;
  }

  
}

@media (min-width: 769px) and (max-width: 800px) {
  .largeCard{
    height: 40vh; 
    margin-top: 2rem;
    

    
    
  }

  .CardHeader h3{
    font-size: 24px;
  }
  .CardHeader h5{
    font-size: 16px;
  }

 
  
}

@media (min-width: 801px) and (max-width: 1024px) {




 

  .CardHeader h3 {
    font-size: clamp(1rem, 1.5vw, 1rem); 
   
 
  }

  .CardHeader h5 {
  font-size: clamp(0.7rem, 1vw, 0.7rem);
  
  }
 

}

@media (min-width: 1025px) and (max-width: 1280px) {

 
  
  .CardHeader h3 {
    font-size: clamp(1rem, 1.5vw, 1.5rem); 
    
    
 
  }

  .CardHeader h5 {
  font-size: clamp(0.7rem, 1vw, 0.8rem);
  
  }
 

}














@media (max-width: 1280px) and (max-height: 650px) {
 
  .largeCard{
    height: 45vh; 
    margin-top: 2rem;
    

    
    
  }
  .CardHeader h3 {
    font-size: 14px; 
    
 
  }

  .CardHeader h5 {
  font-size: 9px;
  
  }
 

}




@media (max-width: 1024px)  and (min-height: 650px) and (max-height: 700px) {
  .largeCard{
    height: 42vh;

    
  
  }

  .CardHeader h3 {
    font-size: 11px; 
 
  }

  .CardHeader h5 {
  font-size: 7px;
  
  }
 
}





@media (max-width: 900px) and (min-height: 650px)and (max-height: 700px) {


.largeCard{
    height: 42vh;

    padding: 1rem; 

  
  }

  .CardHeader h3 {
    font-size: 14px; 
 
  }

  .CardHeader h5 {
  font-size: 9px;
  
  }


}


@media (max-width: 375px) and (min-height: 600px) and (max-height: 667px) {
  .largeCard{
    height: 40vh !important;
    width: 100%!important; 
    
  }

.CardHeader{
  height: 3rem;
  padding: 0.5rem;
}
  .CardHeader h3 {
    font-size: 14px; 
    
 
  }

  .CardHeader h5 {
  font-size: 9px;
  
  }




}


@media (max-width: 800px) and (max-height: 400px) {
  .largeCard{
    height: 80vh !important;
    width: 100%!important; 
    margin-left: 0vh;
    padding: 1rem; 
  }

.CardHeader{
  height: 3rem;
}
  .CardHeader h3 {
    font-size: 14px; 
    
 
  }

  .CardHeader h5 {
  font-size: 9px;
  
  }


}


@media (min-width: 1024px) and (height: 1366px) {

  .largeCard{
    height: 42vh;
    
    

    padding: 0.5rem; 
    // background-color: #806cdb;
  
  }

  .CardHeader h3 {
    font-size: 24px; 
 
  }

  .CardHeader h5 {
  font-size: 16px;
  
  }


}

@media (max-width: 360px) and (max-height: 768px) {
  .largeCard {
    height: 38vh;

    padding: 0.5rem;
    // background-color: #806cdb;

  }

  .CardHeader h3 {
    font-size: clamp(1rem, 1.5vw, 1rem)

  }

  .CardHeader h5 {
    font-size: 9px;

  }
}


