@import '../../../styles/variables';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;

  .ContentBox {
    width: 76rem;
    height: auto;
    background-color: $white;
    padding: 2rem 4rem;
    display: flex;
    flex-direction: column;
    border: 0.5px solid $gray-50;
    box-shadow: 0px 4px 8px rgba(176, 190, 197, 0.3);
    border-radius: 15px;
  }

  .containerHeader {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    margin-bottom: 2.5rem;

    .containerTitle {
      display: flex;
      align-items: center;
      justify-content: center;

      h3 {
        color: $gray-100;
        text-transform: uppercase;
        font-weight: 500;
      }
    }
  }



  .formTitleDP {
    margin-bottom: 1.25rem;

    h3 {
      color: $gray-500;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 1rem;
    }
  }

  .formTitleUC {
    h3 {
      color: $gray-500;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 1rem;
    }
  }



  .containerButtons {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .buttonText {
    border: none;
    background: transparent;
    font-size: 0.8rem;
    text-decoration: none;
    color: $gray-100;
    font-weight: 400;
    text-transform: uppercase;
  }

  .dividerVertical {
    height: 12px;
    width: 1px;
    background: $gray-50;
    margin: 0 15px;
  }

  .dividerHorizontal {
    width: 100%;
    height: 1px;
    background: $gray-50;
    margin-top: 1.25rem;
  }

  .marginTop {
    margin-top: 1.5rem;
  }
}

.PersonButton {
  border-bottom: 0.5px solid $gray-50;
}

.ActiveTabButton {
  border: none;
  background: transparent;
  padding: 8px 20px;
  border-bottom: 5px solid $blue-500;
  color: $blue-500;
  font-weight: 500;
}

.disabledTabButton {
  border: none;
  background: transparent;
  padding: 8px 20px;
  color: $gray-50;
  font-weight: 400;
}

.ucContainerHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.containerPersonInput {
  display: flex;
  flex-direction: column;
}

.InputOrganization {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.25rem;
}

.InputOrganizationUserdata {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.btnClear {
  background-color: $white;
  width: 14rem;
  height: 2.6rem;
  border-radius: 5px;
  border: 1px solid $gray-50;
  color: $blue-500;
  text-transform: uppercase;
  font-size: 0.8rem;
  text-align: center;
}

@media screen and (min-width: 0px) and (max-width: 445px) {

  .associarUnid{
    //width: 100% !important;
    //background-color: #d32f2f;
    min-width: 310px;
    min-height: 130px;
  }

  .editarBox{

    //background-color: #d32f2f;
    width: 100%;
    
  }

  .closeIC{
    position: absolute;
    //background-color: aqua;
    right: 15px;
  }
}

@media screen and (min-width: 446px) and (max-width: 2445px) {

  .associarUnid{
    display: flex;
    //width: 100% !important;
    //background-color: #d32f2f;
    width: 400px;
    height: 150px;
    
  }

  .editarBox{

    //background-color: #d32f2f;
    width: 100%;
    
  }
}