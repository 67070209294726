@import '../../../styles/variables';

.NotConsumer h2 {
  font-size: 1rem;
margin-top: 8%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: $titles;
}

.blurLoading h2 {
  font-size: 0.8rem;
  margin-top: 8%;
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: $titles;
}

    .boxChart {
      display: flex;
      flex-direction: column; // Colocar o gráfico e a legenda em coluna
      align-items: center; 
      justify-content: center;
      width: 111%;
      height: 99%;

      // overflow: hidden;
      position: relative;
    }


    
    @media (max-width: 900px) {
      .boxChart {width: 100%;}

    }
    

    @media (max-width: 1900px) {
      .boxChart {overflow: hidden;}

    }


 