.comparisonContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 10vh;
    padding-left: 3vw;
    padding-right: 3vw;

    .titleContainer {
        display: flex;
        align-items: center;
        height: 3.4vh;
        gap: 0.5vw;
        margin-top: 1vh;
        padding-left: 2.5vw;
       


        .arrowRight {
            cursor: pointer;
            transition: all ease-in-out .1s;

            &:hover {
                scale: 0.92;
            }

            &:active {
                scale: 0.85;
            }
        }

        .title {
            color: #14394C;
            font-size: 3.1vh;
            line-height: 3.1vh;
            font-weight: 600;
        }
    }

}


@media (min-width: 0px) and (max-width: 360px){

  .comparisonContainer{
    margin-top: 12rem !important;
}

  .titleContainer{
    margin-bottom: 2rem;

  }

}

@media (min-width: 361px) and (max-width: 390px)
{


  .comparisonContainer{
    margin-top: 12rem !important;
}
  .titleContainer{
    margin-bottom: 2rem;

  }

}

@media (min-width: 391px) and (max-width: 480px)
{


  .comparisonContainer{
    margin-top: 12rem !important;
}
  .titleContainer{
    margin-bottom: 2rem;

  }

}
@media (min-width: 481px) and (max-width: 900px)
{


  .comparisonContainer{
    margin-top: 12rem;
}
  .titleContainer{
    margin-bottom: 2rem;

  }

}




@media (max-width: 1440px) and (max-height: 700px) {
  .comparisonContainer{
    margin-top: 5rem;
  }
}


  @media (max-width: 1280px) and (max-height: 700px) {
    .comparisonContainer{
      margin-top: 5rem;
    }
  }

  @media (max-width: 1024px) and (max-height: 500px) {
    .comparisonContainer{
      margin-top: 5rem;
    }
  }

  @media (max-width: 900px) and (max-height: 500px) {
    .comparisonContainer{
      margin-top: 6rem;
    }
  }


 


  

  @media (max-width: 480px) and (min-height: 600px){

    .comparisonContainer{
      margin-top: 5rem;
      }
    
}


  @media (max-width: 480px) and (max-height: 400px) {
    .comparisonContainer{
      margin-top: 12rem;
    }

    
  }

 




@media (width: 375px) and (max-height: 812px) {
    .comparisonContainer{
      margin-top: 5rem;
    }
    
  }


  @media (width: 360px) and (max-height: 640px) {
    .comparisonContainer{
      margin-top: 5rem;
    }
  }


  
  