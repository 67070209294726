.chartsContainer {
    display: flex;
    flex-direction: column;
    margin-top: 2.5vh;
    gap: 2.28vh;
    

    .chartContainer {
        display: flex;
        flex-direction: column;
        padding: 2.8vh 2vw;
        border-radius: 24px;
        height: 39.6vh;
        background-color: #FFFFFF;
        overflow: hidden;


        .dateFormattedContainer {
            width: 100%;
            display: flex;
            justify-content: space-between;
            background-color: antiquewhite;
            height: 0px;
            

            .dateAndConsumptionContainer {
                display: flex;
                flex-direction: column;

                .totalConsumption {
                    padding-left: .5rem;
                    color: #14394C;
                    opacity: .7;
                }
            }
        }

        .areaDate {
            background-color: #EAF2F6;
            padding-inline: .625vw;
            border-radius: 5px;
            height: fit-content;
            width: fit-content;
            color: #14394C;
            font-size: 1.66vh;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    }
}



@media (max-width: 900px)  and (max-height: 500px) {
    .chartContainer {

        height: 80vh !important;
  }
}