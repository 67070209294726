.legendContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Ajusta para o conteúdo se alocar corretamente */
    align-items: flex-end;
    width: 100%; /* Tornando o container 100% da largura disponível */
    padding: 0rem;
    max-width: auto; /* Limita a largura máxima, mas ainda é fluido */
    height: auto; /* Permite que a altura se ajuste ao conteúdo */
    /* background-color: aqua; */
}

.chartLegend {
    width: 25vw; /* Garantindo que a legenda ocupe toda a largura do container */
    display: flex;
    flex-direction: column;
    overflow: scroll;
    padding: 0rem;

    max-height: 36vh; /* Controle de altura máxima */
    margin-left: 10rem;
    /* background-color: beige; */
}

.chartLegendItem {
    display: flex;
    align-items: center;
    margin-bottom: 0rem; /* Ajuste o espaçamento entre os itens */
    color: black;
    cursor: pointer;
}

.chartLegendColor {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
}

.chartLegendLabel {
    font-size: 16px;
    color: #2566ff;
    opacity: 0.7;
    font-family: Inter;
    font-weight: 500;
    position: relative;
}

.chartLegendLabel::after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #2566ff;
    opacity: 0.7;
    margin-top: 1px;
    position: absolute;
    top: 80%;
    left: 0;
}

/* Quando o item é clicado, a linha desaparece */
.clickedLabel::after {
    opacity: 0;
}

/* Opacidade modificada quando o item é clicado */
.clickedLabel {
    opacity: 0.7;
}

.hiddenLegendItem {
    opacity: 0.5; /* Ajuste a opacidade conforme necessário */
}

.hiddenLegendLabel {
    color: #999999;
}

.hiddenLegendColor {
    color: #999999;
}

.hiddenLegendLabel::after {
    opacity: 0; /* Opacidade para legendas ocultas */
}

.clickedLabel {
    opacity: 0.7;
}

/* MEDIA QUERIES */

@media (max-width: 320px) {
    .legendContainer {
        height: 6vh;
        width: 75vw;
    }

    .chartLegend {
        flex-wrap: wrap;
        max-height: 6rem;
        overflow-y: scroll;
    }
    .chartLegendItem {
        margin-right: 0.5rem;

        margin-top: 2px;
    }

    .chartLegend {
        position: absolute;
        top: -1rem;
        right: 0rem;
        width: 75vw;
    }

    .chartLegendLabel {
        font-size: 12px;
    }
}

@media (min-width: 321px) and (max-width: 360px) {
    .legendContainer {
        height: 6vh;
        width: 75vw;
    }

    .chartLegend {
        flex-wrap: wrap;
        max-height: 4rem;
        overflow-y: scroll;
        margin-top: 0.5rem;
    }

    .chartLegendItem {
        margin-right: 0.5rem;

        margin-top: 2px;
    }

    .chartLegend {
        position: absolute;
        top: -1rem;
        right: 0rem;
        width: 75vw;
    }

    .chartLegendLabel {
        font-size: 9px;
    }
}

@media (min-width: 361px) and (max-width: 375px) {
    .legendContainer {
        height: 6vh;
        width: 75vw;
    }

    .chartLegend {
        flex-wrap: wrap;
        max-height: 4rem;
        overflow-y: scroll;
        margin-top: 0.5rem;
    }

    .chartLegendItem {
        margin-right: 0.5rem;

        margin-top: 2px;
    }

    .chartLegend {
        position: absolute;
        top: -1rem;
        right: 0rem;
        width: 75vw;
    }

    .chartLegendLabel {
        font-size: 9px;
    }
}

@media (min-width: 376px) and (max-width: 390px) {
    .legendContainer {
        height: 6vh;
        width: 75vw;
    }

    .chartLegend {
        flex-wrap: wrap;
        max-height: 4rem;
        overflow-y: scroll;
        margin-top: 0.5rem;
    }

    .chartLegendItem {
        margin-right: 0.5rem;

        margin-top: 2px;
    }

    .chartLegend {
        position: absolute;
        top: -1rem;
        right: 0rem;
        width: 75vw;
    }

    .chartLegendLabel {
        font-size: 9px;
    }
}
@media (min-width: 391px) and (max-width: 412px) {
    .legendContainer {
        height: 6vh;
        width: 75vw;
    }

    .chartLegend {
        flex-wrap: wrap;
        max-height: 6rem;
        overflow-y: scroll;
    }
    .chartLegendItem {
        margin-right: 0.5rem;

        margin-top: 2px;
    }

    .chartLegend {
        position: absolute;
        top: 0rem;
        right: 0rem;
        width: 75vw;
    }

    .chartLegendLabel {
        font-size: 12px;
    }
}

@media (min-width: 413px) and (max-width: 414px) {
    .legendContainer {
        height: 6vh;
        width: 75vw;
    }

    .chartLegend {
        flex-wrap: wrap;
        max-height: 6rem;
        overflow-y: scroll;
    }
    .chartLegendItem {
        margin-right: 0.5rem;

        margin-top: 2px;
    }

    .chartLegend {
        position: absolute;
        top: 0rem;
        right: 0rem;
        width: 75vw;
    }

    .chartLegendLabel {
        font-size: 12px;
    }
}

@media (min-width: 415px) and (max-width: 480px) {
    .legendContainer {
        height: 6vh;
        width: 75vw;
    }

    .chartLegend {
        flex-wrap: wrap;
        max-height: 6rem;
        overflow-y: scroll;
    }
    .chartLegendItem {
        margin-right: 0.5rem;

        margin-top: 2px;
    }

    .chartLegend {
        position: absolute;
        top: 0rem;
        right: 0rem;
        width: 75vw;
    }

    .chartLegendLabel {
        font-size: 12px;
    }
}
@media (min-width: 481px) and (max-width: 600px) {
    .legendContainer {
        height: 6vh;
        width: 75vw;
    }

    .chartLegend {
        flex-wrap: wrap;
        max-height: 6rem;
        overflow-y: scroll;
    }
    .chartLegendItem {
        margin-right: 0.5rem;

        margin-top: 2px;
    }

    .chartLegend {
        position: absolute;
        top: 0rem;
        right: 0rem;
        width: 75vw;
    }

    .chartLegendLabel {
        font-size: 12px;
    }
}

@media (min-width: 601px) and (max-width: 768px) {
    .legendContainer {
        height: 6vh;
        width: 80vw;
    }

    .chartLegend {
        flex-wrap: wrap;
        max-height: 6rem;
        overflow-y: scroll;
    }
    .chartLegendItem {
        margin-right: 0.5rem;

        margin-top: 2px;
    }

    .chartLegend {
        position: absolute;
        top: 0rem;
        right: 0rem;
        width: 78vw;
    }

    .chartLegendLabel {
        font-size: 12px;
    }
}

@media (min-width: 769px) and (max-width: 900px) {
    .legendContainer {
        height: 6vh;
        width: 80vw;
    }

    .chartLegend {
        flex-wrap: wrap;
        max-height: 5rem;
        overflow-y: scroll;
    }
    .chartLegendItem {
        margin-right: 0.5rem;

        margin-top: 2px;
    }

    .chartLegend {
        position: absolute;
        top: 0rem;
        right: 0rem;
        width: 78vw;
    }

    .chartLegendLabel {
        font-size: 12px;
    }
}

@media (min-width: 901px) and (max-width: 1024px) {
    .chartLegend {
        margin-left: 6rem;
        margin-bottom: 0rem;
        max-height: 34vh; /* Limita a largura máxima, mas ainda é fluido */
    }
    .chartLegendLabel {
        font-size: 11px;
    }
}

@media (min-width: 1025px) and (max-width: 1280px) {
    .chartLegend {
        margin-left: 6rem;
        margin-bottom: 0rem;
        max-height: 34vh; /* Limita a largura máxima, mas ainda é fluido */
    }
    .chartLegendLabel {
        font-size: 11px;
    }
}

@media (min-width: 1281px) and (max-width: 1366px) {
    .chartLegend {
        margin-left: 6rem;
        margin-bottom: 0rem;
        max-height: 34vh; /* Limita a largura máxima, mas ainda é fluido */
    }
    .chartLegendLabel {
        font-size: 12px;
    }
}

@media (min-width: 1367px) and (max-width: 1440px) {
    .chartLegend {
        margin-left: 6rem;
        margin-bottom: 0rem;
        max-height: 34vh; /* Limita a largura máxima, mas ainda é fluido */
    }
    .chartLegendLabel {
        font-size: 14px;
    }
}

@media (min-width: 1441px) and (max-width: 1680px) {
    .chartLegend {
        margin-left: 6rem;
        margin-bottom: 0rem;
        max-height: 34vh; /* Limita a largura máxima, mas ainda é fluido */
    }
}

@media (min-width: 1681px) and (max-width: 1905px) {
    .chartLegend {
        margin-left: 6rem;
        margin-bottom: 0rem;
        max-height: 30vh; /* Limita a largura máxima, mas ainda é fluido */
    }
}

@media (min-width: 1906px) and (max-width: 1919px) {
}

@media (min-width: 1921px) and (max-width: 2300px) {
}

@media (min-width: 2301px) and (max-width: 3000px) {
}

@media (min-width: 3001px) and (max-width: 7000px) {
}

@media (max-width: 1280px) and (max-height: 600px) {
    .chartLegend {
        max-height: 34vh;
        width: 10rem;
        margin-left: 6rem;
    }

    .chartLegendLabel {
        font-size: 10px;
    }
}

@media (min-width: 900px) and (max-width: 1024px) and (max-height: 700px) {
    .chartLegend {
        max-height: 34vh;
        width: 10rem;
        margin-left: 6rem;
    }

    .chartLegendLabel {
        font-size: 10px;
    }
}

@media (min-width: 700px) and (max-width: 900px) and (min-height: 400px) and (max-height: 700px) {
    .chartLegend {
        max-height: 5rem;
        width: 75vw;
        margin-left: 6rem;
    }

    .chartLegendLabel {
        font-size: 10px;
    }
}

@media (max-width: 1024px) and (max-height: 450px) {
}

@media (max-width: 1024px) and (min-height: 400px) and (max-height: 450px) {
}

@media (min-width: 900px) and (max-width: 1024px) and (min-height: 451px) and (max-height: 550px) {
}

@media (min-width: 1000px) and (max-width: 1200px) and (min-height: 551px) and (max-height: 650px) {
}
@media (max-width: 1024px) and (max-height: 250px) {
}

@media (width: 375px) and (height: 667px) {
    .legendContainer {
        height: 6vh;
        width: 75vw;
    }

    .chartLegend {
        flex-wrap: wrap;
        max-height: 4rem;
        overflow-y: scroll;
        margin-top: 0.5rem;
    }

    .chartLegendItem {
        margin-right: 0.5rem;

        margin-top: 2px;
    }

    .chartLegend {
        position: absolute;
        top: -1rem;
        right: 0rem;
        width: 75vw;
    }

    .chartLegendLabel {
        font-size: 9px;
    }
}

@media (max-width: 800px) and (min-height: 200px) and (max-height: 300px) {
    .chartLegend {
        max-height: 5rem;
        width: 75vw;
        margin-left: 6rem;
    }

    .chartLegendLabel {
        font-size: 10px;
    }
}

@media (max-width: 800px) and (min-height: 301px) and (max-height: 400px) {
    .chartLegend {
        max-height: 5rem;
        width: 75vw;
        margin-left: 6rem;
    }

    .chartLegendLabel {
        font-size: 10px;
    }
}

@media (max-width: 500px) and (max-height: 300px) {
    .chartLegend {
        max-height: 3rem;
        width: 75vw;
        margin-left: 6rem;
    }

    .chartLegendLabel {
        font-size: 7px;
    }
}
