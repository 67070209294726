@import '../../../../styles/variables';

.container {
  display: flex;
  flex-direction: column;
}
.header {
  .searchAndAddContainer {
    border-bottom: 1px solid $gray-50;
    padding: 1rem 0px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .formControle {
      padding: 8px 0px;
    }

    .fixMuiInputBaseRoot {
      height: 40px;
      padding-left: 0rem;
      display: flex;
      align-items: center;
    }


    button {
      background: $global-gradient-blue-green;
      width: 14rem;
      height: 40px;
      border-radius: 5px;
      border: none;
      color: $white;
      text-transform: uppercase;
      font-size: 0.8rem
    }
  }

}

.section {
  .itemContainer {
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem !important;
    
  }

  .titletable {
    padding: 0.5rem 2rem !important;
    display: grid;
    grid-template-columns: minmax(150px, 1fr) minmax(100px, 1fr) minmax(150px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr);
    gap: 5px;
    background-color: $blue-25;
    width: 100vw;
    

    .contentHeader {
      h5 {
        color: $gray-500;
        font-weight: 400;
        font-size: 0.8rem;
        text-align: left;
      }
    }
  }

  .actiontable {
    padding: 0rem 2rem !important;
    background-color: $blue-60;
    display: grid;
    grid-template-columns: 30px 1fr 100px;
    align-items: center;

    .contentHeader {
      h5 {
        color: $gray-500;
        font-weight: 400;
        font-size: 0.8rem;
        text-align: left;
        padding: 0.5rem 0rem;
      }

      .deletItens {
        text-transform: uppercase;
        font-size: 0.7rem;
        cursor: pointer;
      }
    }
  }

  .mainContent {
    padding: 1rem 2rem !important;
    display: grid;
    grid-template-columns: minmax(150px, 1fr) minmax(100px, 1fr) minmax(150px, 1fr) minmax(100px, 1fr) minmax(60px, 1fr);
    //gap: 0px;
    align-items: flex-start;
    //background-color: antiquewhite;

    .contentText {
      h4 {
        color: $gray-500;
        font-weight: 400;
        font-size: 1rem;
      }

      p {
        color: $gray-100;
        font-weight: 400;
        font-size: 0.8rem;
      }
    }
  }

  .contentBox3 {
    .contentItem {
        display: grid;
        align-items: center;
        justify-content: flex-end;
        background-color: $white;
        grid-template-columns: 40px 40px;
        //gap: 0px;
        background-color: rgba(220, 224, 233, 0);
      button {
        border: none;
        background: transparent;
        color: $red-200;
      }

      button:first-child {
        color: $blue-500;
      }
    }
  }
}

.containerButtons {
  width: 100%;
  background-color: $white;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 0rem 2rem 1rem 2rem !important;
  border-radius: 15px, 15px;
  margin-bottom: 1.25rem;

  .buttonsGroup {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.8rem;
  }

  .dividerHorizontal {
    width: 100%;
    height: 1px;
    margin-top: 1rem !important;
    background-color: $gray-50;
  }
}

.dividerContainer {
  padding: 0rem 2rem 1rem 2rem !important;
}
.dividerHorizontal {
  width: 100%;
  height: 1px;
  margin-top: 0px !important;
  background-color: $gray-50;
}

@media screen and (min-width: 0px) and (max-width: 1000px){

  


  
}