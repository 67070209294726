@import '../../styles/variables';


.container {
  display: flex;
  justify-content: center;
  height: 10vh; 
  background-color: #F5F6F8;
  
  
}
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #F5F6F8;
  }


.gridContainer {
  display: grid;
  background-color: #F5F6F8;
  height: 100vh; 
  overflow: scroll;
  grid-template-columns: 6.5vw 93.5vw; 
  

  scrollbar-width: none; /* Firefox */
    
  /* Para navegadores baseados em WebKit (Chrome, Safari, Edge) */
  ::-webkit-scrollbar {
    display: none;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-track {
    background: transparent;
  }


  
}

.mainDiv{
  margin-top: 0;
  height: 100%;
  display: flex;
  width: 93.5vw;
  margin-left: 0vw;
  flex-direction: column;
  background-color: #F5F6F8;


}




@media (max-width: 900px) {

  .gridContainer{
  height: 100vh;
  grid-template-columns: 13.5vw 86.5vw; 
  background-color:#F5F6F8;
}

.mainDiv{
  width: 86.5vw;



}


}

@media (min-width: 800px) and (max-width: 899px) {

  .gridContainer{
  height: 100vh;
  grid-template-columns: 13.5vw 86.5vw; 
  background-color: #F5F6F8;
}

.mainDiv{
  width: 86.5vw;



}


}

