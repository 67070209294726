@import '../../styles/variables';

$screen-laptop1024: 1024px;
$screen-laptop1280: 1280px;
$screen-laptop1440: 1440px;
$screen-4k: 2560px;


.container {
  display: flex;
  background-color: #F5F6F8;
  //filter: brightness(1.8);
  background-size: 350%;
  min-height: 100vh;
  
  background-position: 0px -70px ;

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 1.5rem;
    
    
    
  }

  .mainContainer {
    margin-top: 10rem;
    background-color: #fff;
    border-radius: 25px;
    margin-bottom: 1.25rem;
    overflow-x: auto;
    
    

    div {
      padding: 0;
      margin: 0;
    }
  }

  .header {
    padding: 2rem 2rem 0rem 2rem;
    
    
    

    .topHeader {
      h2 {
        color: $gray-500;
        margin-bottom: 1.25rem;
        font-weight: 400;
        font-size: 1.25rem;       
      }

      button {
        text-transform: none !important;
        padding: 0;
        margin-right: 1.25rem;
             
      }
    }
  }

  .filterContainertabPanel {
    border-bottom: 1px solid $gray-50;
    display: flex;
    align-items: center;
    padding: 1rem 1rem 1rem 2rem !important;
    display: none !important;
    
    
    

    h3 {
      color: $gray-500;
      font-weight: 400;
      margin-right: 0.8rem;
      display: none;
      
      
    }

    input {
      height: 30px;
      opacity: 0;
      display: none;
      visibility: hidden;
      display: none;
      
      
    }

    label {
      height: 35px;
      padding: 5px 25px;
      border: 1px solid $gray-50;
      border-radius: 50px;
      background-color: $white;
      color: $gray-100;
      margin-right: 0.8rem;
      display: none;
    }

    input[type="radio"]:checked+label {
      border: 1px solid $gray-50;
      border-radius: 50px;
      background-color: $blue-50;
      color: $gray-500;
      margin-right: 0.8rem;
      display: none;
      
    
    }
  }

  .searchAndAddContainer {
    display: flex;
        justify-content: space-between;
        //background-color: aqua;
        padding: 20px !important;
        
    

    
    


    input[type=text] {
      height: 40px;
      width: 30vw;
      padding: 0px 10px;
      box-sizing: border-box;
      border: 1px solid $gray-50;
      border-radius: 5px;
      transition: 1s;
      outline: none;

      
      
      
    }


    button {
      background: linear-gradient(90deg, rgba(10,112,208,1) 24%, rgba(151,185,49,1) 100%, rgba(0,255,35,0.15303430079155678) 100%);
      width: 30vw;
      height: 40px;
      border-radius: 5px;
      border: none;
      color: $white;
      text-transform: uppercase;
      font-size: 0.8rem;
    }
  }

  .contentTitle {
    background-color: $blue-40;
    padding: 1rem 2rem !important;
    

    h3 {
      color: $gray-500;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 128.91%;
      
    }
  }

  .LoadingScreen {
    height: 200px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    

    div{
      color: $gray-100;
      font-size: 1rem;
      text-align: center;
      width: 60vw;
    }

    button {
      background: $global-gradient-blue-green;
      width: 15rem;
      height: 40px;
      border-radius: 5px;
      border: none;
      color: $white;
      text-transform: uppercase;
      font-size: 0.8rem;
      margin-top: 1.5rem;
    }
  }
}

 
    
  
@media screen and (min-width: 0px) and (max-width: 900px) {

  .contentBox2{
    // display: none;
  }

  .contentBox3{
    // display: none;
  }
  
}

  