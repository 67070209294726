@import "../../../../../../styles/_variables.scss";

.toggleGroup{
  width:100%;
  & button:first-child{
    border-top-left-radius: 16px !important;
    border-bottom-left-radius: 16px !important;
    border-right: 1px solid $white !important;
  }
  & button:last-child{
    border-top-right-radius: 16px !important;
    border-bottom-right-radius: 16px !important;
    border-left: 1px solid $white !important;
  }
}
.toggleButton{
  width: 50%;
  padding: 4px !important;
  background-color: $titles ;
  color: $white ;
  .Mui-selected {
    background-color: $button-Green !important;
    color: $titles !important;
  }
}



