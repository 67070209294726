@import '../../../styles/variables';

.NotConsumer {
  display: flex; /* Define o container como flexbox */
  justify-content: center; /* Centraliza horizontalmente */
  align-items: center; /* Centraliza verticalmente */
  height: 100%; /* Ajuste conforme necessário para a altura total */
  text-align: center; /* Para manter o texto centralizado se quebrar linha */
}

.NotConsumer h2 {
  font-size: 0.8rem;
  color: $titles;
}

.blurLoading{

  height: 100%; /* Ajuste conforme necessário para a altura total */
  display: flex; /* Define o container como flexbox */
  justify-content: center; /* Centraliza horizontalmente */
  align-items: center; /* Centraliza verticalmente */
}
  .blurLoading h2{
    font-size: 0.8rem;
  color: $titles;
  
  
  }
  

.chartContainer {
  width: 100%;
  flex: 1;
  display: flex;

  @media (max-width: 480px) {
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
}

.flexContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.customLegendList {
  margin-top: -4rem;

  &.zoom-110 {
    margin-top: -4rem;
    max-height: 30vh;
    overflow-y: auto; 
  }

  &.zoom-200 {
    margin-top: -4rem;
    max-height: 30vh;
    overflow-y: auto; 
  }

  &.zoom-400 {
    flex-direction: column;
    max-height: 50vh;
    margin-top: 0rem;
  }

  &.zoom-500 {
    flex-direction: column;
    max-height: 50vh;
    margin-top: -5rem;
  }

  
    @media (min-width: 1024px) and (max-width: 1400px) {
    gap: 0;
    overflow-y: scroll; 
    max-height: 26vh;
    margin-top: -2rem;
  }


  @media (min-width: 1401px) and (max-width: 1680px) {
    gap: 0;
    overflow-y: scroll; 
    max-height: 25vh;
    margin-top: 1rem;
  }


  @media (max-width: 480px) {
    margin-top: 0;
    display: flex !important;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    float: none !important;
    position: static !important;
    max-height: 12vh;
    width: 100%;
    overflow-y: scroll; 
  }


  scrollbar-width: none; /* Firefox */
    
  /* Para navegadores baseados em WebKit (Chrome, Safari, Edge) */
  ::-webkit-scrollbar {
    display: none;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-track {
    background: transparent;
  }
}



.legendColorBox {
  
  font-weight: 500;
  opacity: 0.7;
  cursor: pointer;
  position: relative;

  



  &.zoom-175, &.zoom-200, &.zoom-400 {
    font-size: 0.5rem;
  }

  &.zoom-500 {
    font-size: 0.4rem;
  }
  

  @media (max-width: 480px) {
    font-size: .8rem;
    margin-right: 10px;
  }

  @media (max-width: 1600px) {
    font-size: .7rem;
  }
}


.legendColorBox.active::after {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #2566ff; /* Azul */
  opacity: 0.7;
  margin-top: 1px;
  position: absolute;
  top: 100%;
  left: 0;
}

/* Estilo inativo: ::after cinza ou inexistente */
.legendColorBox.inactive::after {
  
  
  opacity: 0;
  
}

.customDot {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;

  @media (max-width: 480px) {
    margin-right: 3px;
    width: 4px;
    height: 4px;
    margin-bottom: 1px;
  }

  @media (min-width: 800px) {
    &.zoom-175, &.zoom-200 {
      width: 5px;
      height:5px;
      }
  }

}

.legend__item {

  @media (max-width: 480px) {
    margin-bottom: 0rem;
  }
}


// ----------- STYLES FOR TABLETS -----------

.chartContainer__tablet {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.customLegendList__tablet {
  margin-top: -4rem;
  margin-top: 0;
  display: flex !important;
  flex-direction: row;
  flex-wrap: wrap;
  gap: .3rem !important;
  justify-content: center;
  align-items: center;
  float: none !important;
  position: static !important;
  width: 100%;
  max-height: 8.7vh;
  overflow-y: scroll; 

  scrollbar-width: none; /* Firefox */
    
  /* Para navegadores baseados em WebKit (Chrome, Safari, Edge) */
  ::-webkit-scrollbar {
    display: none;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-track {
    background: transparent;
  }  
}

.legendColorBox__tablet {
  color: #2567FF !important;
  font-weight: 500;
  border-bottom: 1px solid #2567FF;
  opacity: 0.7;
  cursor: pointer;
  font-size: 1rem;

  @media (max-width: 800px) {
    font-size: .75rem;
  }
}


@media (max-width: 420px) {
  .NotConsumer h2{
       font-size: 0.4rem;
   }
 }




 @media (max-width: 600px) and (max-height: 400px) {

 
  .circle {
    width: 20px !important; /* Define a largura */
    height: 20px !important; /* Define a altura */
  }
}


@media  (min-width: 801px) and (max-width: 1024px) and (max-height: 700px) {


  .NotConsumer h2 {
    font-size: 8px;

  }
 

  .blurLoading h2{

    font-size: 8px;
  }
  .circle {
    width: 20px !important; /* Define a largura */
    height: 20px !important; /* Define a altura */
  }
}


@media (max-width: 800px) and (max-height: 700px) {

 
  .NotConsumer h2 {
    font-size: 8px;

  }
  

  .blurLoading h2{

    font-size: 8px;
  }
  .circle {
    width: 20px !important; /* Define a largura */
    height: 20px !important; /* Define a altura */
  }
}