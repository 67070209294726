@import '../../styles/variables';



  .select {
    display: flex;
    width: 100%;
  }

  .selectMargin{
    margin-right: 3rem;
    margin-left: 0.7rem;

  }

.user{
  display: flex;
  align-items: center;
  gap: var(--Space-400, 1rem);
}

.userInfo {
  display: flex;
  width: 10.5rem;
  flex-direction: column;
  align-items: flex-end;
}

.userInfo h3,
.userInfo h5 {
  white-space: nowrap;         /* Evita que o texto quebre em várias linhas */
  overflow: hidden;            /* Esconde o texto que exceder o tamanho do elemento */
  text-overflow: ellipsis;     /* Adiciona "..." ao final do texto longo */
  max-width: 100%;             /* Limita a largura para caber no contêiner */
}

.userInfo h3 {
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: right;
  color: #14394C !important;
  align-self: stretch;
}


.userInfo h5 {
  margin: 0;
  color: #71892D;
  text-align: right;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  align-self: stretch;
}

.circleBlue{
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%; 
  width: 3rem;
  height: 3rem;
  background-color: #97B73C; 
  box-shadow: none; 
  
  .iconColorWhite{
    color: #30313D;
  }
}


@media (max-width: 359px) {
  .select{
  flex-direction: column; 
  padding: 1rem;
  align-items: center;  
  margin-top: 0rem;
  height: 12rem;
}

.selectMargin{
  margin-right: 0rem;
  margin-left: 0rem;
  margin-bottom: 1rem;   
  }

}


@media (min-width: 360px) and (max-width: 480px) {
  .select{
  flex-direction: column; 
  padding: 0rem;
  align-items: center;  
  margin-top: 1rem;
}

.selectMargin{
  margin-right: 0rem;
  margin-left: 0rem;
  margin-bottom: 1rem;   
  }

}

@media (min-width: 481px) and (max-width: 600px) {
  .select{
    margin-top: 0.5rem;
    }
.selectMargin{
  margin-right: 2rem;
  margin-left: 0rem;
  margin-bottom: 1rem;   
  }

}

@media (min-width: 601px) and (max-width: 800px) {
  .select{
    margin-top: 0.5rem;
    }

  .selectMargin{
    margin-right: 2rem;
  margin-left: 0rem;
    margin-bottom: 1rem;   
    }

  }

  @media (min-width: 801px) and (max-width: 1024px) {

    .select{
      margin-top: 0.5rem;
      }

      .selectMargin{
        margin-right: 2rem;
        margin-left: 0rem;
      }    
    .userInfo h3 {
      font-size: 0.8rem;
 
    }
    
    .userInfo h5 {

      font-size: 0.6rem;

    }

    .circleBlue{
      
      width: 2rem;
      height: 2rem;
      
  }

  }

  @media (min-width: 1025px) and (max-width: 1280px) {
    
    

    .select{
      margin-right: 2rem;
      margin-top: 0.5rem;
      }
    .selectMargin{

      margin-left: 0rem;
  
    }
    .userInfo h3 {
      font-size: 0.8rem;
 
    }
    
    .userInfo h5 {

      font-size: 0.6rem;

    }

    .circleBlue{
      
      width: 2rem;
      height: 2rem;
      
  }
}


@media (min-width: 1281px) and (max-width: 1366px) {


  .select{
    margin-top: 0.5rem;
    }


  .selectMargin{

    margin-left: 0rem;

  }

  .userInfo h3{
    font-size: 0.8rem;
  }
.userInfo h5 {
  font-size: 0.6rem;  
}

  .circleBlue{
  width: 2.5rem;
  height: 2.5rem;
}
}


@media (min-width: 1367px) and (max-width: 1440px) {


  .select{
    margin-top: 0.5rem;
    }
  .selectMargin{

    margin-left: 0rem;

  }

  .userInfo h3{
    font-size: 1rem;
  }
.userInfo h5 {
  font-size: 0.8rem;  
}

  .circleBlue{
  width: 2.5rem;
  height: 2.5rem;
}
}
  @media (min-width: 1367px) and (max-width: 1680px) {

  .select{
  margin-top: 0.5rem;
  }

  
    .userInfo h3{
      font-size: 1rem;
    }
  .userInfo h5 {
    font-size: 0.8rem;  
  }

    .circleBlue{
    width: 3rem;
    height: 3rem;
  }
  }
