@import '../../../styles/variables';

.NotConsumer {
  display: flex; /* Define o container como flexbox */
  justify-content: center; /* Centraliza horizontalmente */
  align-items: center; /* Centraliza verticalmente */
  height: 100%; /* Ajuste conforme necessário para a altura total */
  text-align: center; /* Para manter o texto centralizado se quebrar linha */
  padding-top: 8%;
}

.NotConsumer h2 {
  font-size: 0.8rem;
  color: $titles;
}

.blurLoading{

  height: 100%; /* Ajuste conforme necessário para a altura total */
  display: flex; /* Define o container como flexbox */
  justify-content: center; /* Centraliza horizontalmente */
  align-items: center; /* Centraliza verticalmente */
  padding-top: 8%;
}
  .blurLoading h2{
    font-size: 0.8rem;
  color: $titles;
  
  
  }
  
 

  .boxChart {
    display: flex;
    flex-direction: column; /* Para exibir o gráfico e a legenda em coluna */
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 30vh; /* Ocupa toda a altura disponível */
    // background-color: rgb(233, 181, 181);
    overflow: hidden;
    position: relative;
  }
  
  
  @media (max-width: 800px) and (max-height: 400px) {

    .boxChart{

      margin-top: 4rem;
    }

  }


  
  @media (max-width: 359px) {
    .NotConsumer h2{
      font-size: 0.4rem;
      
      }

  }

  @media (max-width: 420px) {

    .NotConsumer h2{
      font-size: 0.4rem;

      }


  }



  @media (min-width: 376px) and (max-width: 800px) {

    .NotConsumer {
      padding-top: 25%;
    }
    
    
    .blurLoading{
    
      padding-top: 25%;
    }


  }
  @media (max-width: 375px) {

    .NotConsumer {
      padding-top: 50%;
    }
    
    
    .blurLoading{
    
      padding-top: 50%;
    }


  }


  @media (max-width: 600px) and (max-height: 400px) {

   
    .NotConsumer {
      padding-top: 20%;
    }
    
    
    .blurLoading{
    
      padding-top: 20%;
    }
    .circle {
      width: 20px !important; /* Define a largura */
      height: 20px !important; /* Define a altura */
    }
  }


  


  @media  (min-width: 801px) and (max-width: 1024px) and (max-height: 700px) {

    .NotConsumer {
      padding-top: 5%;
    }
    .NotConsumer h2 {
      font-size: 8px;

    }
    
    
    
    .blurLoading{
    
      padding-top: 5%;
    }

    .blurLoading h2{

      font-size: 8px;
    }
    .circle {
      width: 20px !important; /* Define a largura */
      height: 20px !important; /* Define a altura */
    }
  }


  @media (max-width: 800px) and (max-height: 700px) {

    .NotConsumer {
      padding-top: 20%;
    }
    .NotConsumer h2 {
      font-size: 8px;

    }
    
    
    
    .blurLoading{
    
      padding-top: 20%;
    }

    .blurLoading h2{

      font-size: 8px;
    }
    .circle {
      width: 20px !important; /* Define a largura */
      height: 20px !important; /* Define a altura */
    }
  }