@import '../../styles/variables';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-image: url(../Signin//lampada.jpg);
  background-size: 100%;
  background-position: 0px -200px ;
 
  
input.in::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
}



  .ContentBox {
    width: 24rem;
    height: 35rem;
    background: rgba(2, 15, 23, 0.872);
    
    padding: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
   
    box-shadow: 0px 4px 8px rgba(18, 20, 21, 0.2);
    border-radius: 5px;
    color: white;
    
  }

  .containerImage {
    margin-bottom: 2.0rem;
  }

  .containerInput {
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    
    
  }

  

  .containerTextButtons {
    margin-top: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    
    
  }

  .buttonText {
    border: none;
    background: transparent;
    font-size: 0.6rem;
    text-decoration: none;
    color: $gray-100;
    font-weight: 400;
    text-transform: uppercase;
    color: white;
    
  }
  

  .dividerVertical {
    height: 12px;
    width: 1px;
    background: $gray-50;
    margin: 0 15px;
    
  }
}
.btnSnackBar{
  span {
    color: $white !important;
    text-decoration: underline;
    
  }
}

@media screen and (min-width: 150px) and (max-width: 530px) {
  .container{

    background-size: 300%;
  
  background-position: 0px 00px ;
  }
}

@media screen and (min-width: 531px) and (max-width: 600px) {
  .container{

    background-size: 300%;
  
  background-position: 0px 00px ;
  }
}

@media screen and (min-width: 601px) and (max-width: 920px) {

  .container{

    background-size: 300%;
  
  background-position: 0px -100px ;
  }

}

@media screen and (min-width: 921px) and (max-width: 1550px) {
  .container{

    background-size: 200%;
  
  background-position: 0px -300px ;
  }
}

@media screen and (min-width: 1551px) and (max-width: 1920px) {
  .container{

    background-size: 200%;
  
  background-position: 0px -300px ;
  }
}
@media screen and (min-width: 1920px) and (max-width: 2560px) {
  .container{

    background-size: 100%;
  
  background-position: 0px -100px ;
  }
}
