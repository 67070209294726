@import "../../../../../../styles/variables";

.dataPickerByTypeContent {
  padding: 2%;
  margin-top: 12px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  row-gap: 4px;
}

.dataPickerHours {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  column-gap: 8px;
}

.customDatepicker {
  width: 100%;
}

.reactDatePickerHeader {
  color: white;
  border-radius: 14px;
}

.timeCustomDatepicker {
  border-top-right-radius: 14px;
  border-top-left-radius: 14px;
}

.reactDatepickerWrapper {
  width: 113px;
  input {
    width: 100%;
    border-radius: 12px;
    padding: 8px;
    border: 1px solid $gray-70;
    -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
  }
}
