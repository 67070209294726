@import "../../../../../styles/variables";

.middleCard{
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 25px; 
  padding: 1rem; 
  margin-left: 2vh;
  height: 42vh; 
  width: 50%!important;  
  position: relative;
}

.CardHeader {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0.5rem 1.5rem; 

  .containerTitle {
    display: flex;
    flex-direction: column;  
  }

  h3 {
    color: $titles;
    font-style: normal;
    font-weight: 700;
    font-size: clamp(1rem, 1.5vw, 1.5rem); 
    margin: 0; 
  }

  h5 {
    color: #14394c;
    opacity: 0.7;
  font-family: Inter;
  font-size: clamp(0.7rem, 1vw, 1rem);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
    margin-top: -0.3rem;
  }
}

.AreaChartArea {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.containerIcon{
  display: flex;
}

.customDatePicker {
  margin-top: 0.5rem;
  height: 50px;
  background-color: #F5F6F8;
}

.customButton {
  width: clamp(70px, 10.3vw, 198px) !important;
  height: clamp(20px, 6vw, 48px) !important;
  margin-right: 20px !important;
  margin-left: 20px !important;
  padding: clamp(2px, 0.2vw, 10px) !important;
  font-size: clamp(4px, 1.3vw, 16px) !important;
  font-weight: 600 !important;
  background-color: #BDDB6C !important;
  color: #14394C !important;
  border-radius: 25px !important;
  text-transform: none !important;
  margin-top: 0rem !important;
}


@media (max-width: 359px) {
  .middleCard{
    height: 45vh !important;
    width: 100%!important; 
    margin-left: 0vh;
  }

  .CardHeader {
    padding: 0; 
}

.CardHeader h3{
  font-size: 10px;
}
.CardHeader h5{
  font-size: 8px;
}

.customButton {
  font-size: 30% !important;
  height: 80% !important; 
  margin-top: 5px !important;
  width: 10% !important;
  margin-right: 0px !important;
}
}


@media (max-width: 360px) {


.middleCard{
    height: 48vh !important;
    width: 100%!important; 
    margin-left: 0vh;
    
  }

  .CardHeader {
    padding: 0.5rem !important;
}

.CardHeader h3{
  font-size: 12px;
}
.CardHeader h5{
  font-size: 6px;
}


.customButton {
  font-size: clamp(4px, 1.3vw, 6px) !important;
  height: clamp(10px, 6vw, 16px) !important; 
  width: 80px !important;
  margin-right: 0px !important;
  padding: 0 !important;
}
}

@media (min-width: 361px) and (max-width: 375px) {

  .middleCard{
    height: 50vh !important;
    width: 100%!important; 
    margin-left: 0vh;
  }

  .CardHeader {
    padding: 0rem;
}

.CardHeader h3{
  font-size: 16px;
}
.CardHeader h5{
  font-size: 12px;
}
.AreaChartArea {
  margin-top: 0rem;
}

.customButton {
  font-size: clamp(6px, 1.3vw, 8px) !important;
  height: clamp(18px, 6vw, 26px) !important; 
  margin-bottom: 8px !important;
  width: 80px !important;
  margin-right: 0px !important;
}
}
@media (min-width: 376px) and (max-width: 390px) {

  .middleCard{
    height: 50vh !important;
    width: 100%!important; 
    margin-left: 0vh;
  }

  .CardHeader {
    padding: 0; 
}

.CardHeader h3{
  font-size: 16px;
}
.CardHeader h5{
  font-size: 12px;
}


.customButton {
  font-size: clamp(6px, 1.3vw, 8px) !important;
  height: clamp(18px, 6vw, 26px) !important; 
  margin-bottom: 9px !important;
  width: 80px !important;
  margin-right: 0px !important;
}
}

@media (min-width: 391px) and (max-width: 412px) {

  .middleCard{
    height: 50vh !important;
    width: 100%!important; 
    margin-left: 0vh;
  }

  .CardHeader {
    padding: 0; 
}

.CardHeader h3{
  font-size: 16px;
}
.CardHeader h5{
  font-size: 12px;
}
.AreaChartArea {
  margin-top: 0rem;
}

.customButton {
  font-size: clamp(6px, 1.3vw, 8px) !important;
  height: clamp(18px, 6vw, 26px) !important; 
  margin-bottom: 8px !important;
  width: 80px !important;
  margin-right: 0px !important;
}
}


@media (min-width: 413px) and (max-width: 414px) {

  .middleCard{
    height: 50vh !important;
    width: 100%!important; 
    margin-left: 0vh;
  }

  .CardHeader {
    padding: 0; 
}

.CardHeader h3{
  font-size: 16px;
}
.CardHeader h5{
  font-size: 12px;
}
.AreaChartArea {
  margin-top: 0rem;
}

.customButton {
  font-size: clamp(6px, 1.3vw, 8px) !important;
  height: clamp(18px, 6vw, 26px) !important; 
  margin-bottom: 8px !important;
  width: 80px !important;
  margin-right: 0px !important;
}
}






@media (min-width: 415px) and (max-width: 480px) {

  .middleCard{
    height: 50vh !important;
    width: 100%!important; 
    margin-left: 0vh;
  }

  .CardHeader {
    padding: 0; 
}

.CardHeader h3{
  font-size: 18px;
}
.CardHeader h5{
  font-size: 12px;
}
.AreaChartArea {
  margin-top: 0rem;
  
}

.customButton {
  font-size: clamp(8px, 1.3vw, 12px) !important;
  height: clamp(20px, 6vw, 30px) !important; 
  margin-bottom: 5px !important;
  width: 100px !important;
  margin-right: 5px !important;
}
}

@media (min-width: 481px) and (max-width: 599px) {

  .middleCard{
    height: 35vh !important;
    width: 100%!important; 
    margin-left: 0vh;
  }

  .CardHeader {
    padding: 0; 
}

.CardHeader h3{
  font-size: 18px;
}
.CardHeader h5{
  font-size: 12px;
}
.AreaChartArea {
  margin-top: 0rem;
  
}

.customButton {
  font-size: clamp(8px, 1.3vw, 12px) !important;
  height: clamp(20px, 6vw, 30px) !important; 
  margin-bottom: 5px !important;
  width: 100px !important;
  margin-right: 10px !important;
}
}



@media (min-width: 600px) and (max-width: 768px) {
  .middleCard{
    height: 42vh; 
    width: 100%!important;  
    margin-left: 0vh;
    margin-top: 2rem;
    
    
  }

  .CardHeader h3{
    font-size: 20px;
  }
  .CardHeader h5{
    font-size: 14px;
  }

  .customButton {
    font-size: clamp(4px, 1.3vw, 8px) !important;
    height: clamp(20px, 6vw, 30px) !important; 
    margin-bottom: 10px !important;
    width: 120px !important;
    margin-right: 10px !important;
  }
  
}

@media (min-width: 769px) and (max-width: 800px) {
  .middleCard{
    height: 40vh; 
    width: 100%!important;  
    margin-left: 0vh;
    margin-top: 2rem;
    
    
    
    
  }

  .CardHeader h3{
    font-size: 24px;
  }
  .CardHeader h5{
    font-size: 16px;
  }

  .customButton {
    font-size: clamp(4px, 1.3vw, 8px) !important;
    height: 48px!important; 
    // margin-bottom: 10px !important;
    width: 120px !important;
  }
  
}

@media (min-width: 801px) and (max-width: 1024px) {




 

  .CardHeader h3 {
    font-size: clamp(1rem, 1.5vw, 1rem); 
   
 
  }

  .CardHeader h5 {
  font-size: clamp(0.7rem, 1vw, 0.7rem);
  
  }
  .customButton {
    font-size: clamp(4px, 1.3vw, 8px) !important;
    height: 38px !important; 
    // margin-bottom: 7px !important;
    margin-right: 10px !important;
    margin-bottom: 5px !important;
  }

}

@media (min-width: 1025px) and (max-width: 1280px) {

 
  
  .CardHeader h3 {
    font-size: clamp(1rem, 1.5vw, 1.5rem); 
    
    
 
  }

  .CardHeader h5 {
  font-size: clamp(0.7rem, 1vw, 0.8rem);
  
  }
  .customButton {
    font-size: 10px !important;
    height: 38px !important; 
    margin-bottom: 5px !important;
  }

}


@media (min-width: 1281px) and (max-width: 1300px) {

 
  .customButton {
    font-size: clamp(4px, 1.3vw, 11px) !important;
    height: 38px !important; 
    // margin-top: 20px !important;
  }


}

@media (min-width: 1301px) and (max-width: 1366px) {

 
  .customButton {
    font-size: clamp(4px, 1.3vw, 11px) !important;
    height: 48px !important; 
  
  }


}

@media (min-width: 1367px) and (max-width: 1440px) {

 
  .customButton {
    font-size: clamp(4px, 1.3vw, 12px) !important;
    height: 48px !important;
    
  }
}


@media (min-width: 1441px) and (max-width: 1680px) {

  .customButton {
    font-size: 14px !important;
    height: 48px!important;
    
  }
}







@media (max-width: 1280px) and (max-height: 650px) {
 

  .CardHeader h3 {
    font-size: 14px; 
    
 
  }

  .CardHeader h5 {
  font-size: 9px;
  
  }
  .customButton {
    font-size: 9px!important;
    height: 35px !important; 
    margin-bottom: 5px !important;
    margin-right: 0px !important;
  }

}




@media (max-width: 1024px)  and (min-height: 650px) and (max-height: 700px) {
  .middleCard{
    height: 38vh;

    
  
  }

  .CardHeader h3 {
    font-size: 11px; 
 
  }

  .CardHeader h5 {
  font-size: 7px;
  
  }
  .customButton {
    font-size: 8px !important;
    height: 38px !important; 
  }

}





@media (max-width: 900px) and (min-height: 650px)and (max-height: 700px) {


.middleCard{
    height: 38vh;

    padding: 1rem; 

  
  }

  .CardHeader h3 {
    font-size: 14px; 
 
  }

  .CardHeader h5 {
  font-size: 9px;
  
  }
  .customButton {
    font-size: 7px !important;
    height: 38px!important; 
    width: 90px !important ;
    // margin-bottom: 30px !important;
    // margin-right: 5px !important;
  }

}


@media (max-width: 375px) and (min-height: 600px) and (max-height: 667px) {
  .middleCard{
    height: 65vh !important;
    width: 100%!important; 
    
  }

.CardHeader{
  height: 3rem;
  padding: 0.5rem;
}
  .CardHeader h3 {
    font-size: 14px; 
    
 
  }

  .CardHeader h5 {
  font-size: 9px;
  
  }

  .customButton {
    font-size: 6px!important;
    height: 25px !important; 
    // margin-bottom: 22px !important;
    // margin-right: 0px !important;
  }



}


@media (max-width: 800px) and (max-height: 400px) {
  .middleCard{
    height: 80vh !important;
    width: 100%!important; 
    margin-left: 0vh;
    padding: 1rem; 
  }

.CardHeader{
  height: 3rem;
}
  .CardHeader h3 {
    font-size: 14px; 
    
 
  }

  .CardHeader h5 {
  font-size: 9px;
  
  }

  .customButton {
    font-size: 6px!important;
    height: 30px !important; 
    // margin-bottom: 22px !important;
    // margin-right: 0px !important;
    width: 60px;
  }



}


@media (min-width: 1024px) and (height: 1366px) {

  .middleCard{
    height: 42vh;
    
    

    padding: 0.5rem; 
    // background-color: #806cdb;
  
  }

  .CardHeader h3 {
    font-size: 24px; 
 
  }

  .CardHeader h5 {
  font-size: 16px;
  
  }
  .customButton {
    font-size: 8px !important;
    height: 25px !important; 
    // margin-bottom: 10px !important;
    // margin-right: 5px !important;
  }


}

@media (max-width: 360px) and (max-height: 768px) {
  .middleCard {
    height: 42vh;

    padding: 0.5rem;
    // background-color: #806cdb;

  }

  .CardHeader h3 {
    font-size: clamp(1rem, 1.5vw, 1rem)

  }

  .CardHeader h5 {
    font-size: 9px;

  }
}