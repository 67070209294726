@import '../../styles/variables';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  padding: 1.25rem;

  .ContentBox {
    background-color: $white;
    width: 56rem;
    padding: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 0.5px solid $gray-50;
    box-shadow: 0px 4px 8px rgba(176, 190, 197, 0.3);
    border-radius: 15px;
  }

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .contentHeader {
    width: 46rem;
    display: grid;
    grid-template-columns: 140px 3fr 1fr;
    justify-items: center;
    align-items: center;
  }

  .Content {
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 1.25rem 0;

    input:first-child {
      margin: 10px 0px;
    }
  }

  .containerTitle {
    margin-bottom: 2.5rem;
  }

  .titleHeader {
    color: $gray-100;
    font-size: 1rem;
    text-transform: uppercase;
    margin-top: 1.25rem;
  }

  .title {
    color: $gray-500;
    font-size: 1rem;
    text-transform: uppercase;
  }

  .infoText {
    color: $gray-100;
    font-size: 0.9rem;
    font-weight: 400;
    margin-top: 1.25rem;
  }

  .containerInput {
    display: flex;
    flex-direction: column;
  }

  .containerButtons {
    margin-bottom: 1.25rem;
    text-align: center;
  }

  .buttonText {
    border: none;
    background: transparent;
    font-size: 0.6rem;
    text-decoration: none;
    color: $red-200;
    font-weight: 400;
    text-transform: uppercase;
  }

  .positionButton {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .dividerHorizontal {
    width: 100%;
    height: 1px;
    background-color: $gray-50;
    margin: 1.25rem;
  }
}

@media (max-width: 768px) {
  .ContentBox {
    width: 350px !important;
    padding: 3rem;
  }

  .contentHeader {
    width: 280px !important;
    display: flex !important;
    flex-direction: column !important;
  }

  .Content {
    width: 280px !important;
    display: flex;
    align-items: center;

    input {
      width: 280px !important;
    }
  }

  .dividerHorizontal {
    width: 280px !important;
    height: 1px;
    background-color: $gray-50;
    margin: 1.25rem;
  }

  .positionButton {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

@media (max-width: 375px) {
  .ContentBox {
    width: 320px !important;
    padding: 3rem;
  }

  .Content {
    width: 240px !important;
    display: flex;
    align-items: center;

    input {
      width: 240px !important;
    }
  }

  .dividerHorizontal {
    width: 240px !important;
    height: 1px;
    background-color: $gray-50;
    margin: 1.25rem;
  }

  .positionButton {
    display: flex;
    flex-direction: column !important;

    button {
      width: 240px !important;
    }

    button:first-child {
      margin-bottom: 1.25rem;
    }
  }
}