@import '../../styles/variables';

.page {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  padding: 1rem;  

  .box {
    background-color: $white;
    width: 56rem;
    height: 56rem;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;    
    border: 0.5px solid $gray-50;
    box-shadow: 0px 4px 8px rgba(176, 190, 197, 0.3);
    border-radius: 15px;       
  }

  .Logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .contractTex {        
    text-align: justify;
    text-indent: 2rem;
    overflow-y: scroll;
    padding: 1.5rem;
  }

  .acceptbox {    
    align-items: center;
    margin: 1rem;
    text-indent: 0.5rem;
    display: flex;
    margin-top: 1.25rem;
  }

  .title {
    display: flex;
    padding-bottom:0.5rem;
  }
   
  .accept {
    margin-top: 1rem;
    text-align: center;
  }  

  .formprint{
    display: flex;  
    padding: 0.5rem;
  }
}

