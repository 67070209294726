@import '../../../styles/variables';

.InputContainer {
  min-width: 400px;
  display: flex;
  flex-direction: column;

  input {
    width: 100% !important;
    margin-top: 10px;
  }
}

.button {
  background: $global-gradient-blue-green;
  width: 12rem;
  height: 2.6rem;
  border-radius: 5px;
  border: none;
  color: $white;
  text-transform: uppercase;
  font-size: 0.8rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 0px) and (max-width: 445px) {

  .associarUnid{
    //width: 100% !important;
    //background-color: #d32f2f;
    min-width: 310px;
    min-height: 150px;
    
  }

  .editarBox{

    //background-color: #d32f2f;
    width: 100%;
    
  }
}

@media screen and (min-width: 446px) and (max-width: 2445px) {

  .associarUnid{
    //width: 100% !important;
    //background-color: #d32f2f;
    width: 400px;
    height: 150px;
    
  }

  .editarBox{

    //background-color: #d32f2f;
    width: 100%;
    
  }
}
