.inputContainer{
    width: 100%;
    position: relative;
}

.inputIcon {
    width: 16px;
    position: absolute;
    top: 6px;
    right: 8px;
}
