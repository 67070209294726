@import '../../styles/variables';

.container {
  display: flex;
  background-color:#F5F6F8;
  min-height: 100vh;
  

}

  .content {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    padding: 0 1.5rem;
    background-color: #F5F6F8;
    
  }

  .mainContainer {
 
    margin-top: 10rem;
    background-color: #fff;
    border-radius: 25px;
    width: 100%;
    margin-bottom: 1.25rem;
    overflow-x: auto;
    
    
  }

  .header {
    border-bottom: 1px solid $gray-50;
    padding: 2rem 2rem 0rem 2rem;
    
    .topHeader {
      h2 {
        color: $gray-500;
        margin-bottom: 1.25rem;
        font-weight: 400;
        font-size: 1.25rem;       
      }

      button {
        text-transform: none !important;
        padding: 0;
        margin-right: 1.25rem;
        margin-bottom: 1rem;   
      }
    

      .actionHeaderContainer {
        display: flex;
        justify-content: space-between;
        //display: none;
        
        
        
      }

      .searchUser {
        display: flex;
        align-items: center;
        height: 40px;
        width: 30vw !important;
        //min-width: 20px;
        //border: 1px solid $gray-100;
        border-radius: 5px;
        transition: 1s;     
      

      input[type=text] {
        height: 40px;
        width: 30vw !important;
        padding: 0px 10px;
        box-sizing: border-box;
        border: none;
        border-radius: 5px;
        -webkit-transition: 0.5s;
        transition: 0.5s;
        //outline: none;
        
      }

    }
    }

    button {
      //background: rgb(47, 97, 143);
background: linear-gradient(90deg, rgba(10,112,208,1) 24%, rgba(151,185,49,1) 100%, rgba(0,255,35,0.15303430079155678) 100%);
      width: 25vw !important;
      height: 40px;
      border-radius: 5px;
      border: none;
      color: $white;
      text-transform: uppercase;
      font-size: 0.8rem;
      //font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
      
      
    }
  }

  .filterContainer {
    background-color: $blue-40;
    display: flex;
    align-items: center;
    padding: 1.25rem 2.5rem;
    
    
    

    h3 {
      color: $gray-500;
      font-weight: 400;
      margin-right: 0.8rem;
      
    }
  }

  .section {
    .itemContainer {
      display: flex;
      flex-direction: column;
      
      
      
    }

    .titletable {
      display: grid;
      grid-template-columns: 2fr 2fr 2fr 1fr;
      background-color: $blue-25;
      padding: 0 2.5rem;
      display: none;
      
       

      .contentHeader {
        padding: 0.5rem 0.5rem;
        
         

        h5 {
          color: $gray-500;
          font-weight: 400;
          font-size: 0.8rem;
          text-align: left;
          
          
          
        }
      }
    }

    .mainContent {
      display: grid;
      grid-template-columns: 2fr 2fr 2fr 1fr;
      padding: 0 2.5rem;
      
      
      
      
    }

    

    .contentBox {
      .contentItem {
        min-height: 80px;
        display: grid;
        margin-top: 1.7rem;
        background-color: $white;
        grid-template-columns: 50px 1fr;
        gap: 0px;
        margin-right: 10px;
        background-color: rgba(220, 224, 233, 0);
        
        
        
        
        
      }

      .circleIcon {
        background-color: $blue-50;
        border-radius: 50%;
        padding: 0.45rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;


        
        
      }

      .contentText {
        display: inline-block;
        gap: 5px;

        h4 {
          color: $gray-500;
          font-weight: 400;
          font-size: 1rem;
          
        }

        p {
          color: $gray-100;
          font-weight: 400;
          font-size: 0.8rem;
          white-space: nowrap;
          //overflow: hidden;
          text-overflow: ellipsis;
          
        }
        
      }
      
    }
    

    .contentBox2 {
      //display: none;

      .contentItem {
        min-height: 80px;
        display: grid;
        margin-top: 1.7rem;
        background-color: $white;
        grid-template-columns: 40px 2fr;
        gap: 0px;
        background-color: rgba(220, 224, 233, 0);

        .CircleNumber {
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $blue-500;
          border-radius: 50%;

          color: $white;

          
        }

        .contentText {
          display: flex;
          flex-direction: column;
          
          h4 {
            color: $gray-500;
            font-weight: 300;
            font-size: 1rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            //display: none;
          }

          a {
            color: $blue-500;
            font-weight: 500;
            font-size: 0.8rem;
            text-decoration: none;
          }
        }
      }

      .contentItemMeter {
        
        min-height: 80px;
        background-color: $white;
        display: flex;
        margin-top: 1.7rem;
        background-color: rgba(220, 224, 233, 0);

        h4 {
          color: $gray-500;
          font-weight: 400;
          font-size: 1rem;
          
        }
      }
    }

    .contentBox3 {
      
      .contentItem {
        min-height: 80px;
        display: grid;
        align-items: center;
        justify-content: flex-end;
        background-color: $white;
        grid-template-columns: 30px 30px;


        button {
          border: none;
          background: transparent;
          color: $red-200;
          //background-color: aqua;
          
        }

        button:first-child {
          color: $blue-500;
        }
      }
    }
  }

  .containerButtons {
    
    width: 100%;
    background-color: $white;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 0.9rem;
    padding: 0 2.5rem 1.25rem;
    border-radius: 15px, 15px;
    margin-bottom: 1.25rem;
    border-bottom: 1px solid $gray-50;
    background-color: rgba(220, 224, 233, 0);
  }
  
 

.noConsumerUnit {
  
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    width: 500px;
    color: $gray-100;
    font-size: 1rem;
    text-align: center;
  }
}
  .LoadingScreen {
    height: 200px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    span {
      color: $gray-100;
      font-size: 1rem;
      text-align: center;
      width: 500px;
    }

    .buttom {
      background: black;
      width: 15rem;
      height: 40px;
      border-radius: 5px;
      border: none;
      color: $white;
      text-transform: uppercase;
      font-size: 0.8rem;
      margin-top: 1.5rem;
    }
  }

  .link{
    color: $blue-500;
    font-weight: 500;
    font-size: 0.8rem;
    text-decoration: underline;
    cursor: pointer;
    
  }

  