@import '../../../../styles/variables';




.header {
  .searchAndAddContainer {
    border-bottom: 1px solid $gray-50;
    padding: 1rem 0px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .formControle {
      padding: 8px 0px;
    }

    .fixMuiInputBaseRoot {
      height: 40px;
      padding-left: 1rem;
      display: flex;
      align-items: center;
      width: 300px !important;
    }


    button {
      background: $global-gradient-blue-green;
      width: 14rem;
      height: 40px;
      border-radius: 5px;
      border: none;
      color: $white;
      text-transform: uppercase;
      font-size: 0.8rem;
      display: none;
    }
  }
}


.section {
  .itemContainer {
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem !important;
  }

  .titletable {
    display: grid;
    grid-template-columns: minmax(150px, 1fr) minmax(150px, 1fr) minmax(150px, 1fr) 1fr;
    background-color: $blue-25;
    padding: 0rem 2rem !important;

    .contentHeader {
      padding: 0.5rem 0rem;

      h5 {
        color: $gray-500;
        font-weight: 400;
        font-size: 0.8rem;
        text-align: left;
      }
    }
  }

 .mainContent {
    display: grid;
    grid-template-columns: minmax(150px, 1fr) minmax(150px, 1fr)  minmax(100px, 1fr) 1fr;
    padding: 1rem 2rem !important;
    max-width: 80vw; /* Change the value to fit your design */
  color: $gray-500;
  white-space: nowrap;
  
  text-overflow: ellipsis;
  
  }

    .contentText {
      max-width: 600px;}

.mainContent h4 {
  
  //background-color: aqua;
  font-weight: 400;
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 20px;
  margin-left: 0px;
}  
    
     
.mainContent p {
        color: $gray-100;
        font-weight: 400;
        font-size: 0.8rem;
        
      }

  .contentBox2 h4{
    //background-color: aqua;
  }
  .contentBox3 {
    .contentItem {
      display: grid;
      align-items: center;
      justify-content: flex-end;
      //background-color: $white;
      padding: 0 1.25rem;
      grid-template-columns: 40px 40px;
      gap: 0px;
      //display: none;

      button {
        border: none;
        background: transparent;
        color: $red-200;
      }

      button:first-child {
        color: $blue-500;
      }
    }
  }
}

.containerButtons {
  width: 100%;
  background-color: $white;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 0rem 2rem 1rem 2rem !important;
  border-radius: 15px, 15px;
  margin-bottom: 1.25rem;

  button {
    width: 180px;
    height: 40px;
    padding: 0.25rem;
    border: none;
    background: $global-gradient-blue-green;
    border-radius: 5px;
    color: $white;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 1rem;
    text-transform: uppercase;
  }
}

.statusContainer {
  display: flex;
  align-items: center;

  p {
    color: $gray-100 !important;
  }

  .status {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 3px solid green;
    margin-left: 10px !important;
  }
}


.dividerHorizontal {
  width: 100%;
  height: 1px;
  margin-top: 10px;
  background-color: $gray-50;
}

.noConsumerUnit {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    width: 500px;
    color: $gray-100;
    font-size: 1rem;
    text-align: center;
  }
}

.canais{
  .canaisContext{
    display: flex;
    align-items: center;
    gap: 15px;

    .channelsContainer{
      display: flex;
      align-items: center;
      gap: 5px;
    }
      .available{
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border: 3px solid green;
      }
      .occupation{
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border: 3px solid #777;
      }
    .occupation:hover .tooltip{
      display: block;
      visibility: visible;
    }
    .tooltip{
      display: flex;
      align-items: center;
      gap: 5px;
      position: relative;
    }

    .tooltip:hover .TooltipTextOccupation{
      visibility: visible;
    }
    .tooltip:hover .TooltipTextAvailable{
      visibility: visible;
    }

    .TooltipTextOccupation {
      visibility: hidden;
      width: 120px;
      background-color: $gray-50;
      color: #444;
      text-align: center;
      border-radius: 6px;
      padding: 5px 0;
      text-transform: uppercase;
      
      /* Position the tooltip */
      position: absolute;
      margin-left: 25px;
      top: -5px;
      z-index: 100 !important;
    }

    .TooltipTextAvailable {
      visibility: hidden;
      width: 120px;
      background-color: green;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 5px 0;
      text-transform: uppercase;
      
      /* Position the tooltip */
      position: absolute;
      margin-left: 25px;
      top: -5px;
      z-index: 100 !important;
    }
  }
}

@media screen and (min-width: 0px) and (max-width: 920px) {


}
@media screen and (min-width: 601px) and (max-width: 920px) {

  
}

@media screen and (min-width: 921px) and (max-width: 1150px) {

  
}

@media screen and (min-width: 1151px) and (max-width: 1550px) {

  
}


@media screen and (min-width: 1551px) and (max-width: 1920px) {

  
}



@media screen and (min-width: 1920px) and (max-width: 2560px){

  


}

@media screen and (min-width: 2561px) and (max-width: 4000px) {

  
}

  