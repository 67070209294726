@import "./variables";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700;800&display=swap");
@import "./CustomDatePickerStyles/customReactDatepicker.scss";

// Reset de estilo global
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: "Inter", sans-serif; // Aplica Inter como fonte padrão globalmente
  // background-color: aquamarine;

  ::-webkit-scrollbar {
    width: 0.7em;
  }
  ::-webkit-scrollbar-track {
    background: $blue-40;
    border-radius: 20px;
  }
  ::-webkit-scrollbar-thumb {
    background: $blue-60;
    border-radius: 20px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: $blue-70;
  }
}

// Ajuste de responsividade
@media (max-width: 1080px) {
  html {
    font-size: 93.75%;
  }
}

@media (max-width: 720px) {
  html {
    font-size: 87.5%;
  }
}

// Estilos de corpo e textos
body {
  background-color: $background;
  font-family: "Inter", sans-serif; // Garante que o corpo use a fonte Inter
}

body,
input,
textarea,
button {
  font: 500 1rem "Inter", sans-serif; // Aplica Inter a todos os elementos de texto
  color: $gray-500;
}

// Estilos para cabeçalhos
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  font-family: "Inter", sans-serif; // Aplica Inter aos cabeçalhos
  color: $gray-500;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.5rem;
}

button {
  cursor: pointer;
}

#initialHome {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
