@import '../../../styles/variables';




@media screen and (min-width: 0px) and (max-width: 445px) {

  .associarUnid{
    //width: 100% !important;
    //background-color: #d32f2f;
    min-width: 310px;
    min-height: 130px;
  }

  .editarBox{

    //background-color: #d32f2f;
    width: 100%;
    //height: 50vh;
    
  }

.boxButton{
  //background-color: aqua;
  width: 40vw;
}

}

@media screen and (min-width: 446px) and (max-width: 2445px) {

  .associarUnid{
    display: flex;
    //width: 100% !important;
    //background-color: #d32f2f;
    width: 400px;
    height: 150px;
    
  }

  .editarBox{

    //background-color: #d32f2f;
    width: 100%;
    
  }
}