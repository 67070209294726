@import "../../../../styles/variables";

.customModalContainer {
  position: absolute;
  width: 320px;
  background-color: $white;
  border-radius: 8px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 12px;
  border: none !important;
}

.customModalContainer:focus, .customModalContainer:active, .customModalContainer:focus-visible {
    border: none;
}

.customModalHeader{
 display: flex;
 justify-content: flex-end;
}
// o correto seria fazer pelo styled mas...
.customModalBody{
    padding: 5px 24px;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    column-gap: 8px;
    margin-top: 16px;
}

.customModalFooter{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    column-gap: 5px;
}


