@import '../../../styles/variables';

.btnAddImage {
  .inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  label {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 10px;
    background-color: $blue-500;
    border-radius: 5px;
    color: $white;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 14px;

    svg {
      margin-right: 10px;
    }
  }
}

.fixMuiInputBaseRootEditModel {
  height: 40px;
  display: flex;
  align-items: center;
}

ul {
  display: flex !important;
  flex-direction: column;
}

@media screen and (min-width: 0px) and (max-width: 445px) {

  .associarUnid{
    //width: 100% !important;
    //background-color: #d32f2f;
    min-width: 310px;
    min-height: 130px;
  }

  .editarBox{

    //background-color: #d32f2f;
    width: 100%;
    
  }
}
@media screen and (min-width: 446px) and (max-width: 2445px) {

  .associarUnid{
    display: flex;
    //width: 100% !important;
    //background-color: #d32f2f;
    width: 400px;
    height: 150px;
    
  }

  .editarBox{

    //background-color: #d32f2f;
    width: 100%;
    
  }
}