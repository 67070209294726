@import '../../../styles/variables';

.NotConsumer {
  display: flex; /* Define o container como flexbox */
  justify-content: center; /* Centraliza horizontalmente */
  align-items: center; /* Centraliza verticalmente */
  text-align: center; /* Para manter o texto centralizado se quebrar linha */
}

.NotConsumer h2 {
  font-size: 0.8rem;
  color: $titles;
}
  .blurLoading h2{
    font-size: 0.8rem;
  color: $titles;
  
  }
  

    .boxChart {
      display: flex;
      flex-direction: column; // Colocar o gráfico e a legenda em coluna
      align-items: center; 
      justify-content: center;
      width: 100%;
      height: 99%;
      // background-color: rgb(233, 181, 181);
      overflow: hidden; // Impedir que filhos extrapolem os limites
      position: relative;
    }


      

    @media (max-width: 359px) {
      .NotConsumer h2{
        font-size: 0.4rem;
        
        }

    }

    @media (min-width: 360px) and (max-width: 420px) {

      .NotConsumer h2{
        font-size: 0.4rem;
        
        }

       
      
    
    }


    @media (max-width: 600px) and (max-height: 400px) {

   
      .circle {
        width: 20px !important; /* Define a largura */
        height: 20px !important; /* Define a altura */
      }
    }


    @media  (min-width: 801px) and (max-width: 1024px) and (max-height: 700px) {


      .NotConsumer h2 {
        font-size: 8px;
    
      }
     
    
      .blurLoading h2{
    
        font-size: 8px;
      }
      .circle {
        width: 20px !important; /* Define a largura */
        height: 20px !important; /* Define a altura */
      }
    }
    
    
    @media (max-width: 800px) and (max-height: 700px) {
    
     
      .NotConsumer h2 {
        font-size: 8px;
    
      }
      
    
      .blurLoading h2{
    
        font-size: 8px;
      }
      .circle {
        width: 20px !important; /* Define a largura */
        height: 20px !important; /* Define a altura */
      }
    }