@import '../../../../styles/variables';



.section {
  .itemContainer {
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem !important;
    
    
  }
  .mainContainer {
 
    margin-top: 10rem;
    background-color: #fff;
    border-radius: 25px;
    width: 100%;
    margin-bottom: 1.25rem;
    overflow-x: auto;
    
    
  }

  .titletable {
    display: grid;
    grid-template-columns: minmax(150px, 1fr) minmax(150px, 1fr)  minmax(150px, 1fr) 1fr;
    background-color: $blue-25;
    padding: 0rem 2rem !important;
    //display: none !important;
    
    
    

    .contentHeader {
      padding: 0.5rem 0rem;
      

      h5 {
        color: $gray-500;
        font-weight: 400;
        font-size: 0.8rem;
        text-align: left;
        
      }
    }
  }

  .mainContent {
    display: grid;
    grid-template-columns: minmax(150px, 1fr) minmax(150px, 1fr)  minmax(100px, 1fr) 1fr;
    padding: 1rem 2rem !important;
    max-width: 80vw; /* Change the value to fit your design */
  color: $gray-500;
  white-space: nowrap;
  
  text-overflow: ellipsis;
  
  }

.contentText {
      max-width: 600px;}

      
  .mainContent h4 {
  
  //background-color: aqua;
  font-weight: 400;
  font-size: 1rem;
  white-space: nowrap;
  // overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 20px;
  margin-left: 0px;
}  
        
      

      .mainContent p {
        color: $gray-100;
        font-weight: 400;
        font-size: 0.8rem;
        
      }
    
  

      .contentBox3 {
      
        .contentItem {
          min-height: 80px;
          display: grid;
          align-items: center;
          justify-content: flex-end;
          background-color: $white;
          padding: 0 1.25rem;
          grid-template-columns: 40px 40px;
          gap: 0px;
          background-color: rgba(220, 224, 233, 0);
  
          button {
            border: none;
            background: transparent;
            color: $red-200;
            //background-color: aqua;
            
          }
  
          button:first-child {
            color: $blue-500;
          }
        }
      }
    }


.containerButtons {
  width: 100%;
  background-color: transparent !important;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 0rem 2rem 1rem 2rem !important;
  border-radius: 15px, 15px;
  margin-bottom: 1.25rem;

  button {
    width: 180px;
    height: 40px;
    padding: 0.25rem;
    border: none;
    background: $global-gradient-blue-green;
    border-radius: 5px;
    color: $white;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 1rem;
    text-transform: uppercase;
  }
}

.statusContainer {
  display: flex;
  align-items: center;

  p {
    color: $gray-100 !important;
  }

  .status {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 3px solid green;
    margin-left: 10px !important;
  }
}


.dividerHorizontal {
  width: 100%;
  height: 1px;
  margin-top: 10px;
  background-color: $gray-50;
}

.noConsumerUnit {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    width: 500px;
    color: $gray-100;
    font-size: 1rem;
    text-align: center;
  }
}


@media screen and (min-width: 0px) and (max-width: 920px) {



}



