@import '../../styles/variables';

$screen-laptop1024: 1024px;
$screen-laptop1280: 1280px;
$screen-laptop1440: 1440px;
$screen-4k: 2560px;


.container {
  display: flex;
  background-color: #F5F6F8;
  //filter: brightness(1.8);
  background-size: 350%;
  min-height: 100vh;
  
  background-position: 0px -70px ;

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 1.5rem;
    
  }

  .mainContainer {
    margin-top: 1.25rem;
    background-color: #fff;
    border-radius: 25px;
    padding: 2rem;
    margin-top: 10rem;
  } 
}

